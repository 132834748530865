import React from 'react'
import { setSelectedActiveYear, getActiveYears } from 'Redux/actions'
import { useDispatch, useSelector } from 'react-redux'
//import OutlinedSelect from '../OutlinedSelect'
import Select from 'react-select'
import styled from 'styled-components'
const AnioEducativoSelect = () => {
  const dispatch = useDispatch()
  const activeYears = useSelector((store:any) => store.authUser.activeYears)
  const selectedActiveYear = useSelector((store:any) => store.authUser.selectedActiveYear)
  const setLocalStorage = (strYear) => {
    try {
      // const item = JSON.parse(strYear)
      if(typeof !strYear == 'string') 
        localStorage.setItem('anioEducativo', strYear)
      else {
        localStorage.setItem('anioEducativo', JSON.stringify(strYear))
      }
    } catch (_) {

    }
  }
  const getLocalStorage = () => {
    try {
      const obj = localStorage.getItem('anioEducativo')
      const item = JSON.parse(obj)
      return item
    } catch (_) {

    }
  }
  React.useEffect(() => {
    dispatch(getActiveYears())

    const anio = getLocalStorage()
    if (anio) {
      /* dispatch(setSelectedActiveYear(anio)) */
      onChange(anio)
    }

  }, [])
  React.useEffect(() => {
    const anio = getLocalStorage()
    if (!anio && activeYears.length > 0) {
      //dispatch(setSelectedActiveYear(activeYears[0]))
      onChange(activeYears[0])
    }
  }, [activeYears])

  const onChange = (e) => {
    if(!e) return 
    let obj = null
    if(typeof e == 'object') obj = e
    else {
      obj = JSON.parse(e)
    }

    //const item = JSON.parse(obj)
    setLocalStorage(obj)
    dispatch(setSelectedActiveYear(obj))
  }
  if (activeYears.length == 0 && !selectedActiveYear?.id) return <></>

  return (
    <AnioSelect
      classNamePrefix={'select'}
      isSearchable={false}
      onChange={onChange}
      options={activeYears}
      value={selectedActiveYear}
    >
      {/* <option>Año educativo {selectedActiveYear.nombre}</option> */}
      {/* {activeYears?.map((year) => {
			  const isSelectedYear = year.id == selectedActiveYear.id
			  return (
  <option key={year.id} selected={isSelectedYear} value={JSON.stringify(year)}>
    {`Año educativo ${year.nombre}`}
  </option>
			  )
      })} */}
    </AnioSelect>
  )
}
const AnioSelect = styled(Select)`
  & .select__control {
    color:white;
    background: #D8AB1A;
  }
  & .select__menu {
    color:white;
    background: #D8AB1A;
  }
  /* Estilo del contenedor principal del componente */
  .select__container {
    /* Agrega tus estilos personalizados aquí */
    background: #D8AB1A;
    color:white;
  }

  /* Estilo del cuadro de búsqueda del componente */
  .select__search-box {
    color:white;
  }

  /* Estilo de las opciones del componente */
  .select__option {
    background: #D8AB1A;
    color:white;
  }
  .select__option--is-selected {
    background: red;
  }
  .select__option--is-focused {
    background: green;
  }
  
  .select__value-container {
    color:white;
  }
  .select__input-container {    
    color:white;
  }
  .select__single-value {    
    color:white;
  }
`
export default AnioEducativoSelect
