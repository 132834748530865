export const FORM_SAVE = 'FORM_SAVE'
export const FORM_LOAD_CATEGORIES = 'FORM_LOAD_CATEGORIES'
export const FORM_LOAD_FORMS = 'FORM_LOAD_FORMS'
export const FORM_LOAD = 'FORM_LOAD'
export const FORM_RESPONSE_SAVE = 'FORM_RESPONSE_SAVE'
export const FORM_LOAD_DUPLICATED = 'FORM_LOAD_DUPLICATED'
export const FORM_RESPONSES_PAGINATED = 'FORM_RESPONSES_PAGINATED'
export const FORM_RESPONSES = 'FORM_RESPONSES'
export const FORM_STATISTICS = 'FORM_STATISTICS'
export const FORM_SAVE_CATEGORIE = 'FORM_SAVE_CATEGORIE'
export const FORM_DELETE_CATEGORIE = 'FORM_DELETE_CATEGORIE'
export const FORM_GET_USER_CATEGORIES = 'FORM_GET_USER_CATEGORIES'
export const FORM_EDIT_CATEGORIE = 'FORM_EDIT_CATEGORIE'
export const FORM_CREATE_COMUNICADO = 'FORM_CREATE_COMUNICADO'
export const FORM_GET_EMAIL = 'FORM_GET_EMAIL'
export const FORM_GET_URL_BY_FORM = 'FORM_GET_URL_BY_FORM'
export const FORM_DELETE = 'FORM_DELETE'
export const FORM_ADMINS_LOAD = 'FORM_ADMINS_LOAD'
export const FORM_ADMIN_SAVE = 'FORM_ADMIN_SAVE'
export const FORM_ADMIN_SEARCH = 'FORM_ADMIN_SEARCH'
export const FORM_CURRENT_THEME_LOAD = 'FORM_CURRENT_THEME_LOAD'
export const LOADING_CURRENT_THEME = 'LOADING_CURRENT_THEME'
export const FORM_CREATE_INVITACION = 'FORM_CREATE_INVITACION'
export const FORM_VALIDATE_INVITATION = 'FORM_VALIDATE_INVITATION'
