import axios from 'axios'
import { envVariables } from '../../constants/enviroment'

export const createImportacion = async (formData) => {
  try {
    const response = await axios.post<any>(`${envVariables.BACKEND_URL}/api/Importacion`, formData)
    const { data } = response
    if (data.error) {
      throw new Error(data.errorMessage)
    }

    return data.data
  } catch (e) {
    console.log(e)
    throw e
  }
}
export const getTipoImportacionList = async () =>{
  try{
    const response = await axios.get<any>(`${envVariables.BACKEND_URL}/api/Importacion/TiposImportacion`)
    const { data } = response
    if (data.error) {
      throw new Error(data.errorMessage)
    }
    return data.data
  }catch(e){
    console.log(e)
    throw e
  }
}
export const getImportacionList =async ()=>{
  try {
    const response = await axios.get<any>(`${envVariables.BACKEND_URL}/api/Importacion`)
    const { data } = response
    if (data.error) {
      throw new Error(data.errorMessage)
    }

    return data.data
  } catch (e) {
    console.log(e)
    throw e
  }
}
export const execImportacion = async (id)=>{
  try {
    const response = await axios.put<any>(`${envVariables.BACKEND_URL}/api/Importacion/ejecutar/${id}`)
    const { data } = response
    if (data.error) {
      throw new Error(data.errorMessage)
    }

    return data.data
  } catch (e) {
    console.log(e)
    throw e
  }
}

export const obtenerCatalogo = async (catalogo)=>{
  try {
    const response = await axios.get<any>(`${envVariables.BACKEND_URL}/api/Importacion/catalogo`,{
      params: {
        catalogo
      }
    })
    const { data } = response
    if (data.error) {
      throw new Error(data.errorMessage)
    }

    return data.data
  } catch (e) {
    console.log(e)
    throw e
  }
}