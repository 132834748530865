enum types {
  GET_ALL_SCHEDULES = 'GET_ALL_SCHEDULES',
  ADD_SCHEDULE = 'ADD_SCHEDULE',
  DELETE_SCHEDULE = 'DELETE_SCHEDULE',
  UPDATE_SCHEDULE = 'UPDATE_SCHEDULE',
  GET_SINGLE_SCHEDULE = 'GET_SINGLE_SCHEDULE',
  LOADING_SCHEDULES = 'LOADING_SCHEDULES',
  SET_CURRENT_SCHEDULE = 'SET_CURRENT_SCHEDULE',
}

export default types
