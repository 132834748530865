import React from 'react'
import { HiOutlineUpload } from 'react-icons/hi'
import { IoClose } from 'react-icons/io5'
import styled from 'styled-components'
import { guidGenerator } from '../../../utils/GUIDGenerator'
type FileAdd = {
    id: string,
    name: string,
    url: string,
    file?: any
}
interface IProps {
    files: FileAdd[],
    onChangeInputFile: (inputevent: any) => void
    onRemoveFile: (file: FileAdd) => void
    filesAsBase64?: boolean
    maxFileSizeMB?: number
    mimetype?: string
}
const DEFAULT_MAX_FILE_SIZE = 5
/**
 * Componente que permite subir multiples archivos
 */
const FileAppender: React.FC<IProps> = (props) => {
    const files = React.useMemo(() => {
        return props.files
    }, [props.files])
    const onChangeFileInput = (e) => {
        if (e.target.files?.length == 0) return
        const id = guidGenerator()
        const url = URL.createObjectURL(e.target.files[0])
        
        const maxFileSizeMB = props.maxFileSizeMB ?? DEFAULT_MAX_FILE_SIZE
        const maxFileSizeBytes = maxFileSizeMB * 1024 * 1024
        if(e.target.files[0].size > maxFileSizeBytes){
            alert(`El archivo no puede ser mayor de ${maxFileSizeMB} MB`)
            return
        }
        const toBase64 = file => new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = reject;
        })
        
        if (props.filesAsBase64) {
            toBase64(e.target.files[0]).then(file=>{
                props.onChangeInputFile({
                    id,
                    file,
                    name: e.target.files[0].name,
                    url: url
                })
            })
        } else {
            props.onChangeInputFile({
                id,
                file: e.target.files[0],
                name: e.target.files[0].name,
                url: url
            })
        }
    }

    return (
        <section>
            <StyledContainer>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {(files && Array.isArray(files) && files.length > 0) && files.map(obj => {
                        return <StyledFileLabel key={obj.id}><a href={obj.url}>{obj.name}</a><IoClose style={{ cursor: 'pointer' }} size="15px" onClick={() => props.onRemoveFile(obj)} /></StyledFileLabel>
                    })}
                </div>
                <StyledLabel>
                    <HiOutlineUpload size="18px" />
                    <input onChange={onChangeFileInput} style={{ display: 'none' }} accept={props.mimetype} type="file" />
                    Subir archivos
                </StyledLabel>
            </StyledContainer>
        </section>
    )
}
const StyledFileLabel = styled.label`
    background: #F1F5F9;
    border-radius: 26px;
    padding: 4px 8px 4px 12px;
    display:flex;
    gap: 4px;
    align-items: center;
`
const StyledContainer = styled.section`
    border: 1px solid #94A3B8;
    border-radius: 8px;
    width: 100%;
    padding: 10px;
    display:flex;
    justify-content: space-between;

`

const StyledLabel = styled.label`
    border: 1px solid #ccc;
    display: flex;
    padding: 6px 12px;
    cursor: pointer;
    border-radius: 10px;
    margin:0;
`

export default FileAppender