export const LOAD_STUDENT_INFORMATION_CONTACT_BY_USER =
  'LOAD_STUDENT_INFORMATION_CONTACT_BY_USER'
export const LOAD_STUDENT_INFORMATION_CONTACT_BY_USER_LOADING =
  'LOAD_STUDENT_INFORMATION_CONTACT_BY_USER_LOADING'
export const LOAD_STUDENT_INFORMATION_CONTACT_BY_USER_ERROR =
  'LOAD_STUDENT_INFORMATION_CONTACT_BY_USER_ERROR'
export const STUDENT_INFORMATION_CONTACT_BY_USER_CLEAN =
  'STUDENT_INFORMATION_CONTACT_BY_USER_CLEAN '
export const UPDATE_STUDENT_INFORMATION_CONTACT_BY_USER =
  'UPDATE_STUDENT_INFORMATION_CONTACT_BY_USER'
export const CLEAN_STUDENT_INFORMATION_CONTACT_BY_USER =
  'CLEAN_STUDENT_INFORMATION_CONTACT_BY_USER'
export const EXPEDIENTE_CONTACTO_ERROR = 'EXPEDIENTE_CONTACTO_ERROR'
