import React from 'react'
import { ReactComponent as Varon } from 'assets/icons/varon.svg'
import { ReactComponent as Mujer } from 'assets/icons/mujer.svg'
const negativeVerify = (value)=> value < 0 ? 0 :value
const GroupContentCard = ({ onClick, imgSrc, grupo, texto, mujeres, hombres }) => {
	return <section style={{ gap: '5px', display: 'flex', flexDirection: 'column', cursor: 'pointer', borderRadius: '10px', boxShadow: '0 0 5px grey', border: '1px solid lightgrey',height:'247px' }} onClick={onClick}>
		<div style={{display: 'flex', justifyContent: 'center' , borderRadius: '10px',overflow:'hidden',width: '100%', height: '100%' }}>
			<img style={{width: '100%', height: '100%',position:'relative'}} src={imgSrc || 'assets/images/def_image_gestiongrupo.png'} />
		</div>
		<span style={{ marginLeft: '10px' }}>{grupo ? grupo : <br/>}</span>
		<span style={{ marginLeft: '10px' }}>{texto}</span>
		<div style={{ display: 'flex', gap: '5px', padding: '10px' }}>
			<span style={{ fontWeight: 'bold', color: '#02597B', border: '1px solid #02597B', borderRadius: '40px', padding: '3px 8px' }}>{negativeVerify(mujeres)} <Mujer fill="#02597b"/></span>
			<span style={{ fontWeight: 'bold', color: '#02597B', border: '1px solid #02597B', borderRadius: '40px', padding: '3px 8px' }}>{negativeVerify(hombres)} <Varon fill="#02597b"/></span>
		</div>
	</section>
}

export default GroupContentCard