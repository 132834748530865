const DEFAULT_THEME_NAME = 'orange'
const DEFAULT_THEME_COLOR_VALUE = '#01587A'
const COLORES = [
  { color: DEFAULT_THEME_COLOR_VALUE, id: 'orange' },



  { color: '#01587A', id: 'orange' },

  { color: '#2196F3', id: 'blue' },
  { color: '#34F321', id: 'green' },
  { color: '#F32121', id: 'red' }
]
const findColorNameByHex = (hex) => {
  const color = COLORES.find(i => i.color == hex?.toUpperCase())
  return color?.id || DEFAULT_THEME_NAME
}
const findColorValueByName = (name) => {
  const color = COLORES.find(i => i.id == name.toLowerCase())
  return color?.color || DEFAULT_THEME_COLOR_VALUE
}
export default COLORES
export { findColorNameByHex, findColorValueByName, DEFAULT_THEME_COLOR_VALUE, DEFAULT_THEME_NAME }
