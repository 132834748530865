export const COMUNICADOS_LOAD = 'COMUNICADOS_LOAD'
export const COMUNICADOS_ERROR = 'COMUNICADOS_ERROR'
export const COMUNICADOS_LOADING = 'COMUNICADOS_LOADING'
export const COMUNICADOS_BANDEJACORREO_LOAD = 'COMUNICADOS_BANDEJACORREO_LOAD'
export const COMUNICADOS_PLANTILLAS_lOAD = 'COMUNICADOS_PLANTILLAS_lOAD'
export const COMUNICADOS_ESTADOS_LOAD = 'COMUNICADOS_ESTADOS_LOAD'
export const COMUNICADOS_ETIQUETAS_LOAD = 'COMUNICADOS_ETIQUETAS_LOAD'
export const FORM_CREATE_COMUNICADO = 'FORM_CREATE_COMUNICADO'
export const COMUNICADOS_LOAD_SENT = 'COMUNICADOS_LOAD_SENT'
export const DISFUNSION_COMUNICADOS_CREATE = 'DISFUNSION_COMUNICADOS_CREATE'
export const GET_DISFUNSION_COMUNICADOS = 'GET_DISFUNSION_COMUNICADOS'
export const COMUNICADO_FAVORITOS = 'COMUNICADO_FAVORITOS'
export const COMUNICADOS_LOAD_ENTITY_LIST = 'COMUNICADOS_LOAD_ENTITY_LIST'
export const COMUNICADOS_REMOVE_ENTITY_LIST = 'COMUNICADOS_REMOVE_ENTITY_LIST'
export const COMUNICADOS_LOAD_DIFFUSSION_ROLES = 'COMUNICADOS_LOAD_DIFFUSSION_ROLES'
export const COMUNICADOS_LOAD_RECEIVERS = 'COMUNICADOS_LOAD_RECEIVERS'
export const COMUNICADOS_FAVORITOS = 'COMUNICADOS_FAVORITOS'
export const COMUNICADOS_ETIQUETASPERSONALIZADAS_LOAD = 'COMUNICADOS_ETIQUETASPERSONALIZADAS_LOAD'
export const COMUNICADOS_DATAETIQUETASPERSONALIZADAS_LOAD = 'COMUNICADOS_DATAETIQUETASPERSONALIZADAS_LOAD'
export const COMUNICADOS_NUEVOS_TOTAL = 'COMUNICADOS_NUEVOS_TOTAL'
export const COMUNICADOS_BANDEJACORREO_REALTIME_LOAD = 'COMUNICADOS_BANDEJACORREO_REALTIME_LOAD'
