export const MATRICULA_APOYOS_LOADING = 'MATRICULA_APOYOS_LOADING'
export const MATRICULA_APOYOS_LOAD_TIPOS = 'MATRICULA_APOYOS_LOAD_TIPOS'
export const MATRICULA_APOYOS_LOAD_DEPENDENCIAS =
  'MATRICULA_APOYOS_LOAD_DEPENDENCIAS'
export const MATRICULA_APOYOS_LOAD_CATEGORIAS =
  'MATRICULA_APOYOS_LOAD_CATEGORIAS'
export const MATRICULA_APOYOS_LOAD_APOYOS = 'MATRICULA_APOYOS_LOAD_APOYOS'
export const MATRICULA_APOYOS_LOADING_ITEMS = 'MATRICULA_APOYOS_LOADING_ITEMS'
export const MATRICULA_APOYOS_LOAD_DISCAPACIDADES =
  'MATRICULA_APOYOS_LOAD_DISCAPACIDADES'
export const MATRICULA_APOYOS_LOAD_RECURSOS = 'MATRICULA_APOYOS_LOAD_RECURSOS'
export const MATRICULA_APOYOS_LOAD_CONDICIONES =
  'MATRICULA_APOYOS_LOAD_CONDICIONES'
export const MATRICULA_APOYOS_CLEAR_CURRENT_DISCAPACIDADES =
  'MATRICULA_APOYOS_CLEAR_CURRENT_DISCAPACIDADES'
export const MATRICULA_APOYOS_EDIT = 'MATRICULA_APOYOS_EDIT'
export const MATRICULA_APOYOS_DELETE = 'MATRICULA_APOYOS_DELETE'
export const MATRICULA_APOYOS_ADD = 'MATRICULA_APOYOS_ADD'
export const MATRICULA_APOYOS_CONDICIONES_SAVE =
  'MATRICULA_APOYOS_CONDICIONES_SAVE'
export const MATRICULA_APOYOS_DISCAPACIDADES_SAVE =
  'MATRICULA_APOYOS_DISCAPACIDADES_SAVE'
export const MATRICULA_APOYOS_CONDICIONES_RECURSOS_SAVE =
  'MATRICULA_APOYOS_CONDICIONES_RECURSOS_SAVE'
export const MATRICULA_APOYOS_DISCAPACIDADES_RECURSOS_SAVE =
  'MATRICULA_APOYOS_DISCAPACIDADES_RECURSOS_SAVE'
export const MATRICULA_APOYOS_CONDICIONES_RECURSOS_DELETE =
  'MATRICULA_APOYOS_CONDICIONES_RECURSOS_DELETE'
export const MATRICULA_APOYOS_DISCAPACIDADES_RECURSOS_DELETE =
  'MATRICULA_APOYOS_DISCAPACIDADES_RECURSOS_DELETE'
