import React from 'react'
import { ReactComponent as Varon } from 'assets/icons/varon.svg'
import { ReactComponent as Mujer } from 'assets/icons/mujer.svg'
interface IProps {
    oferta: string
    modalidad: string
    servicio: string
    nivel: string
    estudiantesRegistrados: any
    hombres: any
    mujeres: any
    onClick: any
    colorBase?: string
}
const getRGB = (c) => {
    const onlyNumbers = c.replace("#", "");
    const arr = onlyNumbers.split("");
    const color = {
        r: parseInt(arr.slice(0, 2).join().replace(",", ""), 16),
        g: parseInt(arr.slice(2, 4).join().replace(",", ""), 16),
        b: parseInt(arr.slice(4, 6).join().replace(",", ""), 16),
    };
    return color;
};
const getColorBackground1 = (rgb) => {
    return {
        r: rgb.r + 2 > 255 ? 255 : rgb.r + 2,
        g: rgb.g + 13 > 255 ? 255 : rgb.g + 13,
        b: rgb.b + 17 > 255 ? 255 : rgb.b + 17,
    };
};
const getColorBackground2 = (rgb) => {
    return {
        r: rgb.r + 2 > 255 ? 255 : rgb.r + 2,
        g: rgb.g - 8 < 0 ? 0 : rgb.g - 8,
        b: rgb.b - 12 < 0 ? 0 : rgb.b - 12
    }
}
const getColorTag = (rgb) => {
    return {
        r: rgb.r + 92 > 255 ? 255 : rgb.r + 92,
        g: rgb.g + 103 > 255 ? 255 : rgb.g + 92,
        b: rgb.b + 87 > 255 ? 255 : rgb.b + 87,
    }
}
const getHex = (rgb) => {
    let hex = '#'
    hex = hex + rgb.r.toString(16).padStart(2, '0')
    hex = hex + rgb.g.toString(16).padStart(2, '0')
    hex = hex + rgb.b.toString(16).padStart(2, '0')
    return hex
}
const GroupCard: React.FC<IProps> = (props) => {
    const { oferta, modalidad, servicio, nivel, estudiantesRegistrados, hombres, mujeres, onClick } = props

    if (!props.colorBase)
        return <section onClick={onClick} style={{ borderRadius: '10px', boxShadow: '0 0 5px grey', border: '1px solid lightgrey', gap: '5px', cursor: 'pointer', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', padding: '12px 8px 12px 16px' }}>
            <span style={{ fontWeight: "bold", color: "#6B6C7E" }}>{nivel}</span>
            <span style={{ fontStyle: "italic", color: "#272833" }}>{oferta}</span>
            <span style={{ fontStyle: "italic", color: "#272833" }}>{modalidad}</span>
            <span style={{ fontStyle: "italic", color: "#272833" }}>{servicio}</span>
            <span style={{ fontWeight: 'bold', color: '#02597B', border: '1px solid #02597B', borderRadius: '40px', padding: '3px 8px' }}>{estudiantesRegistrados} estudiantes registrados</span>
            <div>
                <span style={{ fontWeight: 'bold', color: '#02597B', border: '1px solid #02597B', borderRadius: '40px', padding: '3px 8px' }}>{mujeres} <i className='icon-Female pink' /></span>
                <span style={{ fontWeight: 'bold', color: '#02597B', border: '1px solid #02597B', borderRadius: '40px', padding: '3px 8px' }}>{hombres} <i className='icon-Male blue' /></span>
            </div>
        </section>
    else {
        const rgb = getRGB(props.colorBase)
        const colorBG1RGB = getColorBackground1(rgb)
        const colorBG2RGB = getColorBackground2(rgb)
        const colorTag = getColorTag(rgb)
        const colorsTheme = {
            title: props.colorBase,
            background: `linear-gradient(90deg, ${getHex(colorBG1RGB)} 68.56%, ${getHex(colorBG2RGB)} 97.65%)`,
            tag: getHex(colorTag)
        }
        
        return <section onClick={onClick} style={{
            color: 'white',
            background: colorsTheme.background,
            borderRadius: '10px',
            boxShadow: '0 0 5px grey',
            border: '1px solid lightgrey',
            gap: '5px',
            cursor: 'pointer',
            display: 'grid',
            gridTemplateColumns: '1fr'
            // display: 'flex', 
            // flexDirection: 'column',
            // alignItems: 'flex-start',
            // padding: '12px 8px 12px 16px'
        }}>
            <div style={{ padding: '12px 8px 12px 10px', borderRadius: 'inherit', width: '100%', background: colorsTheme.title }}>
                <span style={{ fontWeight: "bold" }}>{nivel}</span>
            </div>
            <span style={{ margin: '12px 8px 0 12px', fontStyle: "italic" }}>{oferta}</span>
            <span style={{ margin: '0 8px', fontStyle: "italic" }}>{modalidad}</span>
            <span style={{ margin: '0 8px', fontStyle: "italic" }}>{servicio}</span>

            <div style={{ margin: '12px 8px' }}>
                <span style={{ background: colorsTheme.tag, fontWeight: 'bold', border: '1px solid #02597B', borderRadius: '40px', padding: '3px 8px' }}>{estudiantesRegistrados} Estudiantes Registrados</span>
                <span style={{ background: colorsTheme.tag, fontWeight: 'bold', border: '1px solid #02597B', borderRadius: '40px', padding: '3px 8px' }}>{mujeres} <Mujer fill="white"/></span>
                <span style={{ background: colorsTheme.tag, fontWeight: 'bold', border: '1px solid #02597B', borderRadius: '40px', padding: '3px 8px' }}>{hombres} <Varon fill="white"/></span>
            </div>
        </section>
    }
}


export default GroupCard