export const catalogsEnum = [
  { id: 1, name: 'idTypes' },
  { id: 2, name: 'nationalities' },
  { id: 3, name: 'sexoTypes' },
  { id: 4, name: 'genderTypes' },
  { id: 5, name: 'migrationTypes' },
  { id: 6, name: 'etnias' },
  { id: 7, name: 'lenguasIndigenas' },
  { id: 8, name: 'lenguasMaternas' },
  { id: 9, name: 'estadosCiviles' },
  { id: 10, name: 'territoriosIndigenas' },
  { id: 11, name: 'condicionLaboral' },
  { id: 12, name: 'ocupaciones' },
  { id: 13, name: 'escolaridades' },
  { id: 14, name: 'relacionEstudiante' },
  { id: 15, name: 'discapacidades' },
  { id: 16, name: 'otrasCondiciones' },
  { id: 17, name: 'tipoCE' },
  { id: 18, name: 'tipoRecurso' },
  { id: 19, name: 'tipoAsociacion' },
  { id: 20, name: 'categoriaCE' },
  { id: 21, name: 'tipoInfraestructura' },
  { id: 22, name: 'tipoServicio' },
  { id: 23, name: 'Material Principal' },
  { id: 24, name: 'Dirección de la pendiente' },
  { id: 25, name: 'Región de planificación' },
  { id: 26, name: 'Puesto en Junta Educativa' },
  { id: 27, name: 'organzacionEdif' },
  { id: 28, name: 'componentesEdif' },
  { id: 29, name: 'elementosMenores' },
  { id: 30, name: 'elevacion' },
  { id: 31, name: 'tratamientoDeAguas' },
  { id: 32, name: 'estadosTratamientoDeAguas' },
  { id: 33, name: 'motivosBloqueoCuentaoffice365' },
  { id: 34, name: 'duenioRegistral' },
  { id: 35, name: 'zona' },
  { id: 36, name: 'indiceDesarrolloSocial' },
  { id: 37, name: 'regionSocioEconomica' },
  { id: 38, name: 'tipoEvaluacion' },
  // { id: 38, name: 'alturaRespectoNivelDeCalle' },
  { id: 39, name: 'tipoDocumentos' },
  // { id: 39, name: 'topografiaPredomiante' },
  { id: 40, name: 'tipoDimex' },
  // { id: 40, name: 'regionClimatica' },
  { id: 41, name: 'tipoYisro' },
  /* { id: 41, name: 'climaPredomiante' }, */
  /* { id: 42, name: 'caracteristicaAcceso' }, */
  { id: 42, name: 'estadoAsistencia' },
  { id: 43, name: 'otrasAfectacionesTerreno' },
  // { id: 44, name: 'territorio' },
  { id: 44, name: 'proveedoresDeServicios' },
  { id: 45, name: 'tipoDeCuentaDeCorreoDelFuncionario' },
  { id: 46, name: 'organizacionAuxiliarEstados', type: 46 },
  { id: 47, name: 'pisosMaterialPrincipal' },
  { id: 48, name: 'paredesMaterialPrincipal' },
  { id: 49, name: 'cielorasosMaterialPrincipal' },
  { id: 50, name: 'estructurasTechosMaterialPrincipal' },
  { id: 51, name: 'techoMaterialPrincipal' },
  { id: 52, name: 'canoasBajantesMaterialPrincipal' },
  { id: 53, name: 'puertasVentanasMaterialPrincipal' },
  { id: 54, name: 'estadosElementosMaterialPrincipal' },
  { id: 55, name: 'cerramientoPerimetral' },
  { id: 56, name: 'estadoCerramientoPerimetral' },
  { id: 57, name: 'estacinamientos' },
  { id: 58, name: 'casetaVigilancia' },
  { id: 59, name: 'estado2' },
  { id: 60, name: 'pasosTechados' },
  { id: 61, name: 'numeroDe1A5' },
  { id: 62, name: 'estadoGeneralMuros' },
  { id: 63, name: 'estadoSistemaCamaraCircuito' },
  { id: 64, name: 'proveedordelServicio' },
  { id: 65, name: 'responsabledePago' }
]

export const catalogsEnumObj = {
  IDENTIFICATION: { id: 1, name: 'idTypes' },
  NATIONALITIES: { id: 2, name: 'nationalities' },
  SEXO: { id: 3, name: 'sexoTypes' },
  GENERO: { id: 4, name: 'genderTypes' },
  ESTATUSMIGRATORIO: { id: 5, name: 'migrationTypes' },
  ETNIAS: { id: 6, name: 'etnias' },
  LENGUASINDIGENAS: { id: 7, name: 'lenguasIndigenas' },
  LENGUAMATERNA: { id: 8, name: 'lenguasMaternas' },
  ESTADOCIVIL: { id: 9, name: 'estadosCiviles' },
  TERRITORIOINDIGENA: { id: 10, name: 'territoriosIndigenas' },
  CONDICIONLABORAL: { id: 11, name: 'condicionLaboral' },
  OCUPACIONES: { id: 12, name: 'ocupaciones' },
  ESCOLARIDADES: { id: 13, name: 'escolaridades' },
  RELACIONESTUDIANTE: { id: 14, name: 'relacionEstudiante' },
  DISCAPACIDADES: { id: 15, name: 'discapacidades' },
  OTRASCONDICIONES: { id: 16, name: 'otrasCondiciones' },
  TIPOCE: { id: 17, name: 'tipoCE' },
  TIPORECURSO: { id: 18, name: 'tipoRecurso' },
  TIPOASOCIACION: { id: 19, name: 'tipoAsociacion' },
  CATEGORIACE: { id: 20, name: 'categoriaCE' },
  TIPOINFRAESTRUCTURA: { id: 21, name: 'tipoInfraestructura' },
  TIPOSERVICIO: { id: 22, name: 'tipoServicio' },
  MATERIALPRINCIPAL: { id: 23, name: 'Material Principal' },
  DIRECCIONPENDIENTE: { id: 24, name: 'Dirección de la pendiente' },
  EGIONPLANIFI: { id: 25, name: 'Región de planificación' },
  PUESTOS: { id: 26, name: 'Puesto en Junta Educativa' },
  ORGANIZACIONEDIF: { id: 27, name: 'organzacionEdif' },
  COMPONENTESEDIF: { id: 28, name: 'componentesEdif' },
  ELEMENTOSMENORES: { id: 29, name: 'elementosMenores' },
  ELEVACION: { id: 30, name: 'elevacion' },
  TRATAMIENTODEAGUAS: { id: 31, name: 'tratamientoDeAguas' },
  ESTADOSTRATAMIENTODEAGUAS: { id: 32, name: 'estadosTratamientoDeAguas' },
  MOTIVOSBLOQUEOCUENTAOFFICE365: {
    id: 33,
    name: 'motivosBloqueoCuentaoffice365'
  },
  DUENIOREGISTRAL: { id: 34, name: 'duenioRegistral' },
  ZONA: { id: 35, name: 'zona' },
  INDICEDESARROLLOSOCIAL: { id: 36, name: 'indiceDesarrolloSocial' },
  REGIONSOCIOECONOMICA: { id: 37, name: 'regionSocioEconomica' },
  ALTURARESPECTONIVELCALLE: { id: 38, name: 'alturaRespectoNivelDeCalle' },
  TOPOGRAFIAPREDOMINANTE: { id: 39, name: 'topografiaPredomiante' },
  // REGIONCLIMATICA: { id: 40, name: 'regionClimatica' },
  // CLIMAPREDOMIANTE: { id: 41, name: 'climaPredomiante' },
  CARACTERISTICAACCESO: { id: 42, name: 'caracteristicaAcceso' },
  OTRASACFECTACIONESTERRENO: { id: 43, name: 'otrasAfectacionesTerreno' },
  TERRITORIO: { id: 44, name: 'territorio' },
  PISOSEDIFICACION: { id: 45, name: 'pisosEdificacion' },
  VALORACIONPATRIMONIAL: { id: 46, name: 'valoracionPatrimonial' },
  ESTADOSORGANIZACIONAUXILIAR: { id: 46, name: 'organizacionAuxiliarEstados', type: 46 },
  PISOSMATERIALPRINCIPAL: { id: 47, name: 'pisosMaterialPrincipal' },
  PAREDESMATERIALPRINCIPAL: { id: 48, name: 'paredesMaterialPrincipal' },
  CIELORASOMATERIALPRINCIPAL: { id: 49, name: 'cielorasosMaterialPrincipal' },
  ESTRUCTURASTECHOSMATERIALPRINCIPAL: {
    id: 50,
    name: 'estructurasTechosMaterialPrincipal'
  },
  TECHOMATERIALPRINCIPAL: { id: 51, name: 'techoMaterialPrincipal' },
  CANOASBAJANTESMATERIALPRINCIPAL: {
    id: 52,
    name: 'canoasBajantesMaterialPrincipal'
  },
  PUERTASVENTANASMATERIALPRINCIPAL: {
    id: 53,
    name: 'puertasVentanasMaterialPrincipal'
  },
  ESTADOSELEMENTOSMATERIALPRINCIPAL: {
    id: 54,
    name: 'estadosElementosMaterialPrincipal'
  },
  CERRAMIENTOPERIMETRAL: { id: 55, name: 'cerramientoPerimetral' },
  ESTADOCERRAMIENTOPERIMETRAL: { id: 56, name: 'estadoCerramientoPerimetral' },
  ESTACIONAMIENTOS: { id: 57, name: 'estacinamientos' },
  CASETAVIGILANCIA: { id: 58, name: 'casetaVigilancia' },
  ESTADO2: { id: 59, name: 'estado2' },
  PASOSTECHADOS: { id: 60, name: 'pasosTechados' },
  NUMERODE1A5: { id: 61, name: 'numeroDe1A5' },
  ESTADOGENERALMUROS: { id: 62, name: 'estadoGeneralMuros' },
  ESTADOSISTEMACAMARACIRCUITO: { id: 63, name: 'estadoSistemaCamaraCircuito' },
  PROVEEDORDELSERVICIO: { id: 64, name: 'proveedordelServicio' },
  RESPONSABLEDEPAGO: { id: 64, name: 'responsabledePago' },
  TIPOEVALUACION: { id: 38, name: 'tipoEvaluacion' },
  TIPOSDOCUMENTOS: { id: 39, name: 'tipoDocumentos' },
  TIPODIMEX: { id: 40, name: 'tipoDimex' },
  TIPOYISRO: { id: 41, name: 'tipoYisro' },
  ESTADOASISTENCIA: { id: 42, name: 'estadoAsistencia' }

}
