import axios from 'axios'
import { envVariables } from '../../constants/enviroment'
export const fetchPerfilCentro = (id) => {
    const url = `${envVariables.BACKEND_URL}/api/ExpedienteCentroEducativo/Institucion/GetPerfilCentro`

    return axios.get(url,{
        params:{id}
    }).then((r) => {
        const { data } = r
        return data
    })
}
export const postPerfilCentro = (body) => {
    const url = `${envVariables.BACKEND_URL}/api/ExpedienteCentroEducativo/Institucion/SetPerfilCentro`

    return axios.post(url,body).then((r) => {
        const { data } = r
        return data
    })
}