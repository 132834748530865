import * as Idioma from './Idioma'
import * as Institucion from './Institucion'
import * as Calificacion from './Calificacion'
import * as Importacion from './Importacion'
import * as ExpedienteEstudiante from './ExpedienteEstudiante'
import * as ExpedienteCentro from './ExpedienteCentro'
import * as UsuariosExternos from './UsuariosExternos'
import * as GestionGrupo from './GestionGrupo'
import * as Usuarios from './Usuarios'
export {
  Idioma,
  Institucion,
  Calificacion,
  Importacion,
  ExpedienteEstudiante,
  ExpedienteCentro,
  UsuariosExternos,
  GestionGrupo,
  Usuarios
}
