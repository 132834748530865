export const MATRICULA_LOAD_STUDENT = 'MATRICULA_LOAD_STUDENT'
export const MATRICULA_STUDENT_ERROR = 'MATRICULA_STUDENT_ERROR'
export const MATRICULA_STUDENT_LOADING = 'MATRICULA_STUDENT_LOADING'
export const MATRICULA_CLEAR_STUDENT = 'MATRICULA_CLEAR_STUDENT'
export const MATRICULA_LOAD_MEMBER = 'MATRICULA_LOAD_MEMBER'
export const MATRICULA_LOAD_MEMBERS = 'MATRICULA_LOAD_MEMBERS'
export const MATRICULA_UPDATE_INFORMACION_REGISTRAL =
  'MATRICULA_UPDATE_INFORMACION_REGISTRAL'
export const MATRICULA_UPDATE_IDENTIDAD = 'MATRICULA_UPDATE_IDENTIDAD'
export const MATRICULA_UPDATE_RESIDENCIA = 'MATRICULA_UPDATE_RESIDENCIA'
export const MATRICULA_UPDATE_ENCARGADOS = 'MATRICULA_UPDATE_ENCARGADOS'
export const MATRICULA_UPDATE_APOYOS_EDUCATIVOS =
  'MATRICULA_UPDATE_APOYOS_EDUCATIVOS'
export const MATRICULA_ADD_MEMBER = 'MATRICULA_ADD_MEMBER'
export const MATRICULA_REMOVE_MEMBER = 'MATRICULA_REMOVE_MEMBER'
export const MATRICULA_UPDATE_MEMBER = 'MATRICULA_UPDATE_MEMBER'
export const MATRICULA_CLEAR_MEMBER = 'MATRICULA_CLEAR_MEMBER'
export const MATRICULA_SET_WIZARD_ID = 'MATRICULA_SET_WIZARD_ID'
export const MATRICULA_SET_WIZARD_ID_DATOS = 'MATRICULA_SET_WIZARD_ID_DATOS'
export const MATRICULA_SET_WIZARD_STEPS = 'MATRICULA_SET_WIZARD_STEPS'
export const MATRICULA_SAVE_WIZARD = 'MATRICULA_SAVE_WIZARD'
export const MATRICULA_LOAD_NIVELES = 'MATRICULA_LOAD_NIVELES'
export const MATRICULA_SET_ENTIDADMATRICULAID = 'MATRICULA_SET_ENTIDADMATRICULAID'
export const MATRICULA_SAVE = 'MATRICULA_SAVE'
export const MATRICULA_FILTER = 'MATRICULA_FILTER'
export const MATRICULA_CLEAN_FILTER = 'MATRICULA_CLEAN_FILTER'
export const MATRICULA_ERROR = 'MATRICULA_ERROR'
export const MATRICULA_LOAD_DATOS_EDUCATIVOS = 'MATRICULA_LOAD_DATOS_EDUCATIVOS'

export const MATRICULA_UPDATE_DIRECCION = 'MATRICULA_UPDATE_DIRECCION'
export const MATRICULA_GET_INFOANIOCURSOFECHAS = 'MATRICULA_GET_INFOANIOCURSOFECHAS'
export const MATRICULA_LOAD_INFORMACIONREGISTRAL = 'MATRICULA_LOAD_INFORMACIONREGISTRAL'
export const MATRICULA_LOAD_ANIOSEDUCATIVOS = 'MATRICULA_LOAD_ANIOSEDUCATIVOS'
export const MATRICULA_LOAD_CURSOSLECTIVOS = 'MATRICULA_LOAD_CURSOSLECTIVOS'
export const MATRICULA_SELECT_ANIOEDUCATIVO = 'MATRICULA_SELECT_ANIOEDUCATIVO'
export const MATRICULA_SELECT_CURSOLECTIVO = 'MATRICULA_SELECT_CURSOLECTIVO'
export const MATRICULA_INFO = 'MATRICULA_INFO'
