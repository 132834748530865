export const CONFIGURATION_LOAD_INSTITUTIONS = 'CONFIGURATION_LOAD_INSTITUTIONS'
export const CONFIGURATION_ERROR = 'CONFIGURATION_ERROR'
export const CONFIGURATION_LOAD_REGIONALES = 'CONFIGURATION_LAOD_REGIONALES'
export const CONFIGURATION_LOAD_ALL_REGIONALES = 'CONFIGURATION_LOAD_ALL_REGIONALES'
export const CONFIGURATION_LOAD_REGIONAL = 'CONFIGURATION_LOAD_REGIONAL'
export const CONFIGURATION_LOAD_CIRCUITOS = 'CONFIGURATION_LOAD_CIRCUITOS'
export const CONFIGURATION_LOAD_CIRCUITO = 'CONFIGURATION_LOAD_CIRCUITO'
export const CONFIGURATION_LOAD_DIRECTORES = 'CONFIGURATION_LOAD_DIRECTORES'
export const CONFIGURATION_LOAD_DIRECTOR = 'CONFIGURATION_LOAD_DIRECTOR'
export const CONFIGURATION_LOAD_USERS = 'CONFIGURATION_LOAD_USERS'
export const CONFIGURATION_LOAD_SEDES = 'CONFIGURATION_LOAD_SEDES'
export const CONFIGURATION_LOAD_PARENT_INTSTITUTIONS = 'CONFIGURATION_LOAD_PARENT_INTSTITUTIONS'
export const CONfIGURATION_ERROR_DATA = 'CONfIGURATION_ERROR_DATA'
export const CONFIGURATION_LOAD_CURRENT_CENTER = 'CONFIGURATION_LOAD_CURRENT_CENTER'
export const CONFIGURATION_CLEAN_INSTITUTIONS = 'CONFIGURATION_CLEAN_INSTITUTIONS'
export const LOAD_CURRENT_INSTITUTION_DIRECTOR = 'LOAD_CURRENT_INSTITUTION_DIRECTOR'
export const LOAD_LOCATION = 'LOAD_LOCATION'
export const CONFIGURATION_LOAD_ALL_CIRCUITOS = 'CONFIGURATION_LOAD_ALL_CIRCUITOS'
export const CONFIGURACION_LOAD_SEARCH_INSTITUTIONS = 'CONFIGURACION_LOAD_SEARCH_INSTITUTIONS'
export const SEDES_DELETE = 'SEDES_DELETE'
export const SET_EXPEDIENTE_REGIONAL = 'SET_EXPEDIENTE_REGIONAL'
export const SET_EXPEDIENTE_SUPERVISION = 'SET_EXPEDIENTE_SUPERVISION'
