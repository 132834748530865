export const LOAD_MODEL_LEVELS = 'LOAD_MODEL_LEVELS'
export const LOAD_MODEL_SPECIALTIES = 'LOAD_MODEL_SPECIALTIES'
export const CLEAN_CURRENT_MODEL = 'CLEAN_CURRENT_MODEL'
export const LOAD_CURRENT_MODEL = 'LOAD_CURRENT_MODEL'
export const CREATE_MODEL_OFFER = 'CREATE_MODEL_OFFER'
export const LOAD_MODEL_OFFERS = 'LOAD_MODEL_OFFERS'
export const LOAD_EDITED_MODEL = 'LOAD_EDITED_MODEL'
export const DELETE_MODELS = 'DELETE_MODELS'
export const LOAD_LEVEL_OFFERS = 'LOAD_LEVEL_OFFERS'
export const LOAD_LAST_ID = 'LOAD_LAST_ID'
export const CLEAN_STATE_LEVELS_ESP = 'CLEAN_STATE_LEVELS_ESP'
export const ENABLE_AND_DISABLE = 'ENABLE_AND_DISABLE'
export const UPDATE_OFFER = 'UPDATE_OFFER'
