enum UserCatalog {
  GET_ALL_USUARIO_CATALOGOS_BY_ROL_ID = 'GET_ALL_USUARIO_CATALOGOS_BY_ROL_ID',
  LOADING_USUARIO_CATALOGOS = 'LOADING_USUARIO_CATALOGOS',
  EDIT_USUARIO_CATALOGO = 'EDIT_USUARIO_CATALOGO',
  REMOVE_USUARIO_CATALOGO = 'REMOVE_USUARIO_CATALOGO',
  GET_ROLES_BY_USER_ID = 'GET_ROLES_BY_USER_ID',
  GET_USER_BY_CIRCUITO_ID = 'GET_USER_BY_CIRCUITO_ID',
  GET_USERS_BY_REGIONAL_ID = 'GET_USERS_BY_REGIONAL_ID',
  CREATE_USER_BY_REGIONAL_ID = 'CREATE_USER_BY_REGIONAL_ID',
}

export default UserCatalog
