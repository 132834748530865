import axios from 'axios'
import { envVariables } from '../../constants/enviroment'

export const getAllStudentsWithoutGroup = (nivelId: number, institucionId: number) => {
    return axios.get(
        `${envVariables.BACKEND_URL}/api/GestionGrupo/EstudiantesFullGrupo/${institucionId}/${nivelId}/`
    ).then(r=>{
        return r.data
    })
}

