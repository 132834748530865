export const SET_HORARIO = 'SET_HORARIO'
export const SET_ESTUDIANTES_ENCARAGADO = 'SET_ESTUDIANTES_ENCARAGADO'
export const SET_INCIDENCIAS_ESTUDIANTE = 'SET_INCIDENCIAS_ESTUDIANTE'
export const SET_ASISTENCIA_ESTUDIANTE = 'SET_ASISTENCIA_ESTUDIANTE'
export const SET_CALIFICACIONES_ESTUDIANTE = 'SET_CALIFICACIONES_ESTUDIANTE'
export const SET_USUARIO_ACTUAL = 'SET_USUARIO_ACTUAL'
export const SET_ESTUDIANTE_INDEX = 'SET_ESTUDIANTE_INDEX'
export const SET_INFO_MALLA = 'SET_INFO_MALLA'
export const SET_SELECTED_ESTUDIANTE = 'SET_SELECTED_ESTUDIANTE'
export const SET_SELECTED_ESTUDIANTE_INFO_ACADEMICA = 'SET_SELECTED_ESTUDIANTE_INFO_ACADEMICA'
export const SET_SELECTED_INSTITUTION = 'SET_SELECTED_INSTITUTION'

export const SET_ALL_INFO_ACADEMICA = 'SET_ALL_INFO_ACADEMICA'
export const SET_INFO_GENERAL = 'SET_INFO_GENERAL'
