import colorList from '../../constants/ColorList'
import { setTemaValue } from 'Redux/tema/actions'
import StoreConfigurator from '../../redux/StoreConfigurator'
const store = StoreConfigurator.getStore()
const { tema } = store.getState()

const getColor = () => {
  try {
    const settings = localStorage.getItem('institutionSetting')
    if (!settings || settings == '') return colorList[0].color

    const settingsObj = JSON.parse(settings)
    const color = settingsObj.color
    const colorHex = colorList.find(i => i.id == color).color
    return colorHex ?? colorList[0].color
  } catch (e) {
    return colorList[0].color
  }
}

const getColorId = () => {
  try {
    const settings = localStorage.getItem('institutionSetting')
    if (!settings || settings == '') return colorList[0].id
    const settingsObj = JSON.parse(settings)
    const color = settingsObj.color
    return color || colorList[0].id
  } catch (e) {
    console.log(e)
    return colorList[0].id
  }
}

const temaObj = {
  ...tema,
  primary: getColor(),
  cambiarTema: (obj) => store.dispatch(setTemaValue(obj)),
  getColor,
  getColorId
}
export default temaObj
/*
const Colors = () => {
	const dispatch = useDispatch()
	const tema = useSelector((store:any)=>{
		return store.tema
	})
	console.log("EL TEMA!!!!!!!!!!!!",tema)
	return {
		...tema,
		cambiarTema: (obj)=>dispatch(setTemaValue(obj))
		primary: '#F15C21', //'#145388',
		secondary: '#109ed9',
		gray: '#D4D4D4',
		darkGray: '#575757',
		opaqueGray: '#dbdbdb', // Se usa para los border
		error: '#bd0505'
	}
}

export default Colors */

/* export default {
  primary: '#F15C21',//'#145388',
  secondary: '#109ed9',
  gray: '#D4D4D4',
  darkGray: '#575757',
  opaqueGray: '#dbdbdb', // Se usa para los border
  error: '#bd0505',
} */
