export const ROLES_LOAD = 'ROLES_LOAD'
export const ROLES_SECTIONS_LOAD = 'ROLES_SECTIONS_LOAD'
export const ROLES_PROFILES_LOAD = 'ROLES_PROFILES_LOAD'
export const ROLES_LOADING = 'ROLES_LOADING'
export const ROLES_ERROR = 'ROLES_ERROR'
export const ROLES_CREATE = 'ROLES_CREATE'
export const ROLES_DELETE = 'ROLES_DELETE'
export const ROLES_TIPO_LOAD = 'ROLES_TIPO_LOAD'
export const ROLES_GET_LOAD = 'ROLES_GET_LOAD'
export const ROLES_MODULOS_LOAD = 'ROLES_MODULOS_LOAD'
export const ROLES_APARTADOS_LOAD = 'ROLES_APARTADOS_LOAD'
export const ROLES_UPDATE = 'ROLES_UPDATE'
export const ROLES_CLEAR = 'ROLES_CLEAR'
export const ROLES_CREATE_PERFIL = 'ROLES_CREATE_PERFIL'
