import React from 'react'
import styled from 'styled-components'
const Button = (props) => {
	return <DefButton {...props}>{props.children}</DefButton>
}
interface IProp {
	outline: boolean
}
const DefButton = styled.button<IProp>`
    ${(props: any) => {
		if (props.outline) {
			return `
			/*border: 1px solid #01587A;*/
			border: none;
			background: white;
			color: #475569;
			&:hover{
				cursor:pointer;
				border: 1px solid #016a93;
			}
		`
		} else {
			return `
			background: #01587A;
			border-style: none;
			color: white;
			&:hover{
				cursor:pointer;
				background-color: #016a93;
				border-color: #016a93;
			}
		`
		}
	}}
    
    border-radius: 8px;
    padding: 12px 16px;
    /*width: 100%;*/
    flex-basis: fit-content;
    
`

export default Button
