export const LOAD_INDICADOR_APRENDIZAJE = 'LOAD_INDICADOR_APRENDIZAJE'
export const LOAD_INDICADOR_APRENDIZAJE_PAGINATED = 'LOAD_INDICADOR_APRENDIZAJE_PAGINATED'
export const SET_INDICADOR_DATA = 'SET_INDICADOR_DATA'
export const ADD_INDICADOR_DATA_COLUMN = 'ADD_INDICADOR_DATA_COLUMN'
export const ADD_INDICADOR_DATA_ROW = 'ADD_INDICADOR_DATA_ROW'
export const REMOVE_INDICADOR_DATA_COLUMN = 'REMOVE_INDICADOR_DATA_COLUMN'
export const REMOVE_INDICADOR_DATA_ROW = 'REMOVE_INDICADOR_DATA_ROW'
export const SET_COLUMN_COLOR = 'SET_COLUMN_COLOR'
export const SET_CELL_TEXT = 'SET_CELL_VALUE'
export const SET_COLUMN_TEXT = 'SET_COLUMN_TEXT'
export const SET_ROW_NAME_TEXT = 'SET_ROW_NAME_TEXT'
export const ADD_CONTENIDO = 'ADD_CONTENIDO'
export const REMOVE_CONTENIDO = 'REMOVE_CONTENIDO'
export const POST_JSON_DATA = 'POST_JSON_DATA'
export const GET_JSON_DATA = 'GET_JSON_DATA'
export const TOGGLE_HABILITAR_PUNTOS = 'TOGGLE_HABILITAR_PUNTOS'
export const SET_CONTENIDO_NAME = 'SET_CONTENIDO_NAME'
export const SET_COLUMN_PUNTOS = 'SET_COLUMN_PUNTOS'
