export const TRASLADO_STUDENTS = 'TRASLADO_STUDENTS'
export const TRASLADO_STUDENT_DATA = 'TRASLADO_STUDENT_DATA'
export const TRASLADO_NIVELES = 'TRASLADO_NIVELES'
export const TRASLADO_NIVEL_DATA = 'TRASLADO_NIVEL_DATA'
export const TRASLADO_CLEAR_NIVEL = 'TRASLADO_CLEAR_NIVEL'
export const TRASLADO_CLEAR_STUDENT = 'TRASLADO_CLEAR_STUDENT'
export const TRASLADO_CLEAR_STUDENTS = 'TRASLADO_CLEAR_STUDENTS'
export const TRASLADO_CENTROS = 'TRASLADO_CENTROS'
export const TRASLADO_CENTRO_DATA = 'TRASLADO_CENTRO_DATA'
export const TRASLADO_LOADING = 'TRASLADO_LOADING'
export const TRASLADO_CLEAR_CENTRO = 'TRASLADO_CLEAR_CENTRO'
export const TRASLADO_CLEAR_CENTROS = 'TRASLADO_CLEAR_CENTROS'
export const TRASLADO_SET_WIZARD_STEPS = 'TRASLADO_SET_WIZARD_STEPS'
export const TRASLADOS_DATA = 'TRASLADO'
export const TRASLADO_SET_TRASLADO = 'TRASLADO_SET_TRASLADO'
export const TRASLADO_SAVE = 'TRASLADO_SAVE'
export const TRASLADO_CLEAR = 'TRASLADO_CLEAR'
export const TRASLADOS_CLEAR = 'TRASLADOS_CLEAR'
export const TRASLADO_REVISION_UPDATE = 'TRASLADO_REVISION_UPDATE'
export const TRASLADO_ERROR = 'TRASLADO_ERROR'
export const TRASLADO_ADD_MOTIVO = 'TRASLADO_ADD_MOTIVO'
export const TRASLADO_ADD_MOTIVO_RECHAZO = 'TRASLADO_ADD_MOTIVO_RECHAZO'
export const TRASLADO_REVISION_RESULT = 'TRASLADO_REVISION_RESULT'
export const TRASLADO_DIRECTOR_TIENE_PERMISO = 'TRASLADO_DIRECTOR_TIENE_PERMISO'
export const TRASLADO_SET_VALIDADOR = 'TRASLADO_SET_VALIDADOR'
export const TRASLADO_CLEAR_VALIDADOR = 'TRASLADO_CLEAR_VALIDADOR'
export const TRASLADO_EXTERIOR = 'TRASLADO_EXTERIOR'
