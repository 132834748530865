/* Gogo Language Texts

Table of Contents

01.General
02.User Login, Logout, Register
03.Menu
04.Error Page
07.Countdown
08.Password Form
*/

export default {
  /* 01.General */
  'general.copyright': 'Ada Intelligence © Todos los derechos reservados.',

  /* 02.Inicio de sesión de usuario, cierre de sesión, registro */
  'user.login-title': 'Iniciar sesión',
  'user.new-password-title':
		'Para continuar debe de realizar el cambio de la contraseña actual, a continuacion, digite su nueva contraseña',
  'user.password-confirm': 'Confirmar contraseña',
  'user.new-password-button': 'Guardar contraseña',
  'user.register': 'Registro',
  'user.forgot-password':
		'Si ha olvidado su contraseña, ingrese su correo electrónico y se le enviará un mensaje para poder recuperarla.',
  'user.email': 'Email',
  'user.password': 'Contraseña',
  'user.forgot-password-question': '¿Contraseña olvidada?',
  'user.fullname': 'Nombre completo',
  'user.login-button': 'INICIAR SESIÓN',
  'user.register-button': 'REGISTRO',
  'user.reset-password-button': 'ENVIAR',
  'user.buy': 'COMPRAR',
  'user.username': 'Usuario',
  'user.logout': 'Cerrar sesión',
  'user.changePassword': 'Cambiar contraseña',
  'user.noAcceess': 'No tiene permisos para acceder a esta vista',

  /* 03.Menú */
  'menu.app': 'Inicio',
  'menu.niveles-groups': 'Grupos de Niveles',
  'menu.dashboards': 'Tableros',
  'menu.tests': 'Pruebas',
  'menu.detail': 'Detalles',
  'menu.list': 'Lista',
  'menu.second-menu': 'Segundo menú',
  'menu.second': 'Segundo',
  'menu.ui': 'IU',
  'menu.charts': 'Gráficos',
  'menu.chat': 'Chatea',
  'menu.survey': 'Encuesta',
  'menu.todo': 'Notas',
  'menu.search': 'Búsqueda',
  'label.grupos': 'Grupos',
  'label.catalogs': 'Catalogos',
  'menu.searchLabel': 'Escriba aquí las palabras claves que desea buscar...',
  'menu.docs': 'Docs',
  'menu.blank-page': 'Blank Page',
  'menu.admin': 'Admin',
  'menu.instituciones': 'Centros educativos',
  'menu.miInstitucion': 'Mi centro educativo',
  'menu.centro': 'Centro educativo',
  'menu.regionales': 'Regionales',
  'menu.periodos': 'Periodos',
  'menu.reportes': 'Reportes',
  'menu.configuracion': 'Configuración',
  'menu.buscadorsupervisiones': 'Supervisiones circuitales',
  'menu.circuitos': 'Circuitos',
  'menu.usuarios': 'Usuarios',
  'menu.roles': 'Roles',
  'menu.registro': 'Registro de Estudiantes',
  'menu.enviarDatos': 'Enviar datos',
  'menu.director': 'Director',
  'menu.form-wizard': 'Enviar datos',
  'menu.niveles': 'Niveles',
  'menu.especialidades': 'Especialidades',
  'menu.ofertas': 'Ofertas',
  'menu.identidad': 'Identidad de la persona',
  'menu.direcciones': 'Direcciones Regionales',
  'menu.supervisiones': 'Supervisiones Circuitales',
  'menu.modalidades': 'Modalidades',
  'menu.servicios': 'Servicios',
  'menu.agrupaciones': 'Agrupaciones',
  'menu.transmitir': 'Transmitir datos',
  'menu.dashboard': 'Panel de director',
  'menu.institucion': 'Centro educativo',
  'menu.configuration': 'Configuración',
  'menu.gestorcatalogo': 'Gestor de Catálogos',
  'menu.ayuda': 'Ayuda',
  'menu.alerta-temprana': 'Alerta Temprana',
  'menu.alerta-estadisticas': 'Estadísticas',
  'menu.alerta-catalogo': 'Catálogo de Alertas',
  'menu.alerta-estudiante': 'Alertas por Estudiante',
  'menu.activar-alerta': 'Activar Alerta',
  'menu.alerta-solicitud': 'Solicitudes de Alertas',
  'menu.alertas-configuracion': 'Configurar proceso',
  'menu.alerta-reportes': 'Reportes',
  'menu.administradores-mep': 'Administradores MEP',
  'menu.gestores-mep': 'Gestores MEP',
  'menu.directores-regionales': 'Directores Regionales',
  'menu.supervisores-circuitos': 'Supervisores de Circuitos',
  'menu.directores-centro': 'Directores de instituciones',
  'menu.invitaciones': 'Invitaciones',
  'menu.matricula': 'Matrícula',
  'menu.prematricula': 'Prematricula',
  'menu.matricula-estudiantes': 'Matricular Estudiantes',
  'menu.estudiantes-matriculados': 'Estudiantes Matriculados',
  'menu.traslados': 'Traslados',
  'menu.registrar-exclusion': 'Registrar Exclusión',
  'menu.': 'Centro educativo',
  'menu.administrativos': 'Administrativos',
  'menu.profesores': 'Profesores',
  'menu.padres-familia': 'Padres de Familia (Encargados)',
  'menu.estudiantes': 'Estudiantes',
  'menu.info-contacto': 'Información de Contacto',
  'menu.ofertas-educativas': 'Ofertas Educativas',
  'menu.estadisticas': 'Estadística ',
  'menu.anio': 'Año Educativo',
  'menu.formularioCreate': 'Crear formulario',
  'menu.formularioList': 'Lista de formularios',
  'menu.formularioTheme': 'Temas',
  'menu.buscadordirecciones': 'Buscador Direcciones',
  'label.groupMembers': 'Miembros del grupo',
  'label.catalogos': 'Catálogos',
  'label.plantillas': 'Plantillas de Correo',
  'label.mensajes': 'Mensajes',

  /* Catalodo centralizado */
  'catalogo.condicionLaboral': 'Condición laboral',
  'catalogo.discapacidades': 'Discapacidades',
  'catalogo.escolaridades': 'Escolaridades',
  'catalogo.estadosCiviles': 'Estados civiles',
  'catalogo.etnias': 'Etnias',
  'catalogo.genderTypes': 'Género',
  'catalogo.idTypes': 'Tipos de identificación',
  'catalogo.lenguasIndigenas': 'Lenguas indigenas',
  'catalogo.lenguasMaternas': 'Lenguas materna',
  'catalogo.migrationTypes': 'Tipos de migración',
  'catalogo.nationalities': 'Nacionalidades',
  'catalogo.ocupaciones': 'Ocupaciones',
  'catalogo.otrasCondiciones': 'Otras condiciones',
  'catalogo.relacionEstudiante': 'Parentezco',
  'catalogo.sexoTypes': 'Sexo',
  'catalogo.territoriosIndigenas': 'Territorios indigenas',
  // Singular
  'catalogo.estadoMigratorio': 'Estado Migratorio',
  'catalogo.etniaIndigena': 'Etnia Indígena',
  'catalogo.lenguaIndigena': 'Lengua Indígena',
  'catalogo.lenguaLescoCR': 'Lengua de señas costarricense (LESCO)',
  'catalogo.lenguaMaterna': 'Lengua materna',
  'catalogo.estadoCivil': 'Estado Civil',

  /* Catalodo centralizado End */
  /* Custom Bread Crumb */
  'breadcrumb.home': 'Inicio',
  'breadcrumb.centro': 'Centro educativo',
  'breadcrumb.centros': 'Centros educativos',
  'breadcrumb.general': 'Información general',
  'breadcrumb.contact': 'Contacto',
  'breadcrumb.house': 'Hogar',
  'breadcrumb.benefits': 'Beneficios',
  'breadcrumb.supervisiones': 'Supervisiones Circuitales1',
  'breadcrumb.educational-supports': 'Apoyos educativos',
  'breadcrumb.curriculous-area': 'Área curricular',
  'breadcrumb.educationalOffers': 'Ofertas Educativas',
  'breadcrumb.health': 'Salud',
  'breadcrumb.educational-offer': 'Oferta educativa',
  'breadcrumb.sinirube': 'SINIRUBE',
  'breadcrumb.user-account': 'Cuentas de usuario',
  'breadcrumb.offertas': 'Ofertas educativas',
  'breadcrumb.hummanResources': 'Recurso humano',
  'breadcrumb.schedules': 'Horarios',
  'breadcrumb.infraestructura': 'Infraestructura',
  'breadcrumb.auxOrganization': 'Organización auxiliar',
  'breadcrumb.auxinformacion': 'Información auxiliar',

  'breadcrumb.resumen': 'Resumen',
  'breadcrumb.gruposProyecciones': 'Grupos y proyecciones',
  'breadcrumb.anioEducativo': 'Año educativo',
  'breadcrumb.email-account': 'Cuenta de correo',
  'breadcrumb.catalogo': 'Catálogos',
  'breadcrumb.plantillasCorreo': 'Plantillas de Correo',
  'breadcrumb.mensajes': 'Mensajes',
  'breadcrumb.normativaInterna': 'Normativa Interna',
  'breadcrumb.location': 'Ubicación',
  'breadcrumb.director': 'Director',
  /* End Custom Bread Crumb */

  /* Creador de formularios */

  'creador.container': 'Contenedor',
  'creador.text': 'Texto simple',
  'creador.textArea': 'Área de texto',
  'creador.unic': 'Selección única',
  'creador.checklist': 'Checklist',
  'creador.radio': 'Radio',
  'creador.multiple': 'Selección múltiple',
  'creador.location': 'Locación',
  'creador.coordinates': 'Coordenadas',
  'creador.locationExact': 'Ubicación exacta',
  'creador.image': 'Imagen',
  'creador.uploadFile': 'Subida de archivo',
  'creador.date': 'Fecha',
  'creador.switch': 'Interruptor',
  'creador.CrudTable': 'Tabla',

  /* End Creador de formularios */

  /* CRUD action */
  'crud.delete': 'Eliminar',
  'crud.edit': 'Editar',
  'crud.select': 'Seleccionar',

  /* 04.Error  */
  'pages.error-title': 'Vaya, parece que ha ocurrido un error!',
  'pages.error-400':
		'Lo sentimos, la página que buscas no pudo ser encontrada',
  'pages.error-400-title': 'PÁGINA NO ENCONTRADA',
  'pages.error-code': 'Código de error',
  'pages.go-back-home': 'REGRESAR',
  /* 05.Register */
  'txt.sex': 'Sexo',
  'txt.status': 'Estado',
  'register.activePeriods': 'Periodos Activos',
  'register.aboutContact': 'Datos del centro educativo',
  'register.legalRepresentative': 'Representante Legal',
  'txt.registered': 'Estudiantes Registrados',

  /* 06 Nivel Institucional */

  'levelInstitutional.addLevel': 'Nuevo nivel Institucional',
  'levelInstitutional.addTypeLevel': 'Nuevo nivel Institucional',

  /* 07 Countdown */
  'countdown.days': 'Días',
  'countdown.hours': 'Horas',
  'countdown.minutes': 'Minutos',
  'countdown.seconds': 'Segundos',

  /* 08 Password Form */
  'passwordForm.feedback': 'La contraseña debe contener:',
  'passwordForm.fieldRequired': 'Este campo es requerido',
  'passwordForm.lowercaseRequired': 'Al menos una letra minúscula',
  'passwordForm.uppercaseRequired': 'Al menos una letra mayúscula',
  'passwordForm.numbersRequired': 'Al menos un número',
  'passwordForm.specialCharacterRequired':
		'Al menos un caracter especial o símbolo',
  'passwordForm.minLengthRequired': 'Al menos 8 caracteres',
  'passwordForm.maxLengthRequired': 'Como máximo 15 caracteres',
  'passwordForm.identicalRequired': 'Las contraseñas deben coincidir',
  'passwordForm.great': 'Genial, tu contraseña es segura!',

  /* */
  /* Forms Element */
  /* */
  /* labels */
  'label.periods': 'Periodos',
  'label.salud': 'Información de salud',
  'label.human': 'Recurso humano',
  'label.typeId': 'Tipo de identificación',
  'label.nacionality': 'Nacionalidad',
  'label.especialidad': 'Especialidad',
  'label.name': 'Nombre',
  'label.lastName': 'Primer Apellido',
  'label.secondSurname': 'Segundo apellido',
  'label.birthDate': 'Fecha de nacimiento',
  'label.female': 'Mujer',
  'label.male': 'Hombre',
  'label.edlLvl': 'Nivel educativo',
  'label.yearProgress': 'Año que cursa',
  'label.period': 'Periodo',
  'label.refugee': 'Refugiado',
  'label.repeating': 'Repitente',
  'label.welcome': '¡Hola!',
  'label.delete': 'Eliminar',
  'label.view': 'Ver',
  'label.buscador': 'Buscador',
  'label.update': 'Actualizar',
  'label.ficha': 'Ficha de centro educativo',
  'label.fichaEstudiante': 'Ficha de la persona',
  'label.sedes': 'Sedes asociadas al centro educativo',
  'label.aux_organizacion': 'Organización auxiliar',
  /* buttons */
  'button.impersonar': 'Ver centro educativo',
  'label.personas': 'Buscador de personas',
  'button.add': 'Agregar',
  'button.edit': 'Editar',
  'button.activate': 'Activar',
  'button.inactivate': 'Inactivar',
  'button.find': 'Buscar',
  'button.save': 'Guardar',
  'button.DIMEX': 'DIMEX',
  'button.PASSPORT': 'PASSPORT',
  'button.CEDULA': 'CÉDULA',
  'button.cancel': 'Cancelar',
  'button.submit': 'Enviar',
  'button.resend': 'Reenviar',
  'button.remove': 'Eliminar',
  'button.restoreRegister': 'Restaurar Envío',
  'button.restore': 'Restaurar',
  'button.changePassword': 'Cambiar Contraseña',
  'button.restorePassword': 'Restablecer Contraseña',
  'button.forwardInvitation': 'Reenviar invitación',
  'button.select': 'Seleccionar',
  'label.centros': 'Centros educativos',
  'label.services': 'Servicios',
  'label.offers': 'Ofertas',
  'label.levels': 'Niveles',
  'label.modalities': 'Modalidades',
  'label.especialities': 'Especialidades',
  'label.informacionGeneral': 'Información general',
  'label.ofertasEducaivas': 'Ofertas educativas',
  'label.asignDirector': 'Director del centro educativo',

  /* wizard */
  'wizard.infoGeneral': 'Información general',
  'wizard.otrosDatos': 'Otros datos',
  'wizard.residencia': 'Residencia',
  /* END wizard */
  /* wizard */
  'wizard.step-name-1': 'Otros datos',
  'wizard.step-name-2': 'Residencia',
  'wizard.step-name-3': 'Encargados23',
  'wizard.step-name-4': 'Apoyos educativos',
  'wizard.step-name-5': 'Matrícula',
  'wizard.content-thanks': 'Enviar datos',
  'wizard.registered': 'foo',
  'wizard.prev': 'Anterior',
  'wizard.next': 'Siguiente',
  'wizard.print': 'Imprimir',
  'wizard.cancel': 'Cancelar',
  'wizard.accept': 'Aceptar',
  'wizard.requestMove': 'Solicitar Traslado',
  'wizard.save': 'Guardar',
  /* select */
  'option.dimex': 'Dimex',
  'option.passport': 'Pasaporte',
  'option.id': 'Cédula',
  /* Form */
  'form.firstName': 'Primer Nombre',
  'form.secondName': 'Segundo Nombre',
  'form.lastName': 'Primer apellido',
  'form.secondLastName': 'Segundo apellido',
  'form.id': 'Identificación',
  'form.email': 'Correo electrónico',
  'form.rolSelected': 'Rol',
  'form.password': 'Contraseña',
  'form.newPassword': 'Nueva Contraseña',
  'form.confirm': 'Confirmar contraseña',
  'form.idType': 'Tipo de Identificación',
  'form.nationality': 'Nacionalidad',
  'form.role': 'Rol',
  'form.civilStatus': 'Estado Civil',
  'form.gender': 'Sexo',
  'form.birthDate': 'Fecha de nacimiento',
  'form.secondEmail': 'Correo electrónico alternativo',
  'form.phoneNumber': 'Teléfono principal',
  'form.secondPhoneNumber': 'Teléfono alternativo44',
  'form.username': 'Nombre de usuario',
  'form.nationalityId': 'Nacionalidad',
  'form.idNacional': 'Identificación',
  'form.socialStatus': 'Estado Social',
  'pages.save': 'Guardar',
  'pages.cancel': 'Cancelar',
  'form.actualPassword': 'Contraseña actual',
  'form.startDate': 'Fecha de inicio',
  'form.finishDate': 'Fecha de finalización',
  'form.status': 'Estado',
  'form.nombreAgrupacion': 'Nombre de agrupación',
  'form.educationalOffers': 'Oferta educativa',
  'form.modalities': 'Modalidad',
  'form.services': 'Servicio',
  'form.specialitties': 'Especialidades',
  'form.freeCourses': 'Cursos libres',
  'form.levels': 'Niveles',
  'form.age': 'Edad',

  /* Instituciones */
  'label.discapacidades': 'Discapacidades',
  'label.institutions': 'Centros educativos',
  'label.budgetaryCode': 'Código Presupuestario',
  'label.circuit': 'Circuito Escolar',
  'label.code': 'Identificador',
  'label.institutionType': 'Tipo centro educativo',
  'label.modalContent': 'Contenido modal',
  'institution.alias': 'Conocido como',
  'institution.fax': 'FAX',
  'institution.phone': 'Teléfono principal',
  'institution.nospecified': 'No Especificado',
  'institution.province': 'Provincia',
  'institution.secondPhone': 'Teléfono secundario',
  'institution.canton': 'Cantón',
  'institution.email': 'Correo principal',
  'institution.ditrict': 'Distrito',
  'institution.secondMail': 'Correo secundario',
  'institution.population': 'Poblado',
  'institution.address': 'Dirección:',

  /* Acciones  */
  'error.edit': 'Se produjo un error al editar el registro',
  'done.edit': ' El registro fue editado exitosamente',
  'error.save': 'Se produjo un error al guardar el registro',
  'done.save': 'El registro fue creado exitosamente',
  'error.delete': 'Se produjo un error al eliminar el registro',
  'done.delete': 'El registro fue eliminado exitosamente',
  'error.forwardInvitation': 'Se produjo un error al reenviar la invitación',
  'done.forwardInvitation': 'La invitacion se ha enviado correctamente',
  'done.shipmentRestoration': 'La restauración del envío está hecha',
  'error.resetpasword': 'Se produjo un error al restablecer la contraseña',
  'done.resetpasword': 'La contraseña ha sido restablecida',

  /* Modal Confirmar eliminacion */
  'dialog.deleteTitle': '¿Estás seguro? ',
  'dialog.deleteInfo':
		'Una vez eliminado, ¡no podrá recuperar este registro!  ',
  'dialog.restorePasswordTitle': '¿Estás seguro? ',
  'dialog.restorePasswordInfo':
		'Restaurar la contraseña al usuario seleccionado',
  'dialog.shipmentRestoration':
		'Restaurar envío al centro educativo seleccionado',
  'dialog.forwardInvitationTitle': '¿Estás seguro? ',
  'dialog.forwardInvitationInfo':
		'Reenviar la invitación al usuario seleccionado',
  'label.ordeBy': 'Ordenar por ',
  'label.add': 'Agregar',
  'label.edit': 'Actualizar',
  'label.users': 'Usuarios',

  /* Modalidades */
  'label.modalities': 'Modalidades',

  /* Regionales */
  'label.regionals': 'Regionales',
  'label.circuits': 'Circuitos',
  'label.regional': 'Regional',
  'label.requiredFields': 'Campos requeridos ( * )',
  'label.telefono': 'Telefono',
  'label.correo': 'Correo',

  /* Restaurar Contraseña */
  'label.passwordDontMatch': 'Las contraseñas no coinciden',
  'label.verificationCode': 'Contraseña temporal',
  'label.userNotFound': '¡Oh, no! El usuario no fue encontrado',
  'label.errorUserFound': '¡Oh no! se produjo un error al obtener el usuario',
  'label.invalidVerificationCode':
		'Código de verificación no válido proporcionado, por favor intente nuevamente',
  'text.passwordRecovery': 'Recuperación de contraseña',
  'text.codeEmail':
		'Si el usuario ingresado está en nuestra base de datos' +
		' Recibirá un código en su correo electrónico con el que puede cambiar su contraseña',
  'session.expired': 'La sesión ha expirado',
  'session.continueBrowsing': 'Continuar navegando',
  'delete.eliminateYourself': 'No se puede eliminarse a usted mismo',
  'session.youWantToContinue':
		'Si desea continuar haga clic en el siguiente boton',
  'session.expiredIn': 'Su sesión expirará en',
  /* Especialidades */
  'label.state': 'Estado',
  'label.specialties': 'Especialidades',
  /* Ofertas */
  'label.ofertas': 'Ofertas',
  /* Family */
  'family.livesWithStudent': 'Vive con estudiante',
  'family.asociation': 'Asociación',
  'family.relationship': 'Relación con estudiante',
  'family.onCharge': '¿Es el encargado del estudiante?',
  'family.uploadFile': 'Subir Archivo',
  'family.uploadedFiles': 'Ver',
  'family.legalRepresentative': '¿Es el representante legal del estudiante?',
  'family.authorized': 'Autorizado',
  'family.dependsOfStudent': '¿Depende economicamente del estudiante?',
  /* apoyo */
  'apoyo.dependencies': 'Dependencia (requerido)',
  'apoyo.types': 'Tipo de apoyo (requerido)',
  'apoyo.detail': 'Detalle del apoyo (opcional)',
  'apoyo.verified': '¿Se verificó el la recepción del apoyo?',
  'apoyo.dateFrom': 'Fecha de aprobación ',
  'apoyo.dateTo': 'Fecha de levantamiento ',

  /** Matricula Forms */

  'matricula.telefonoPrincipal': 'Teléfono principal',
  'matricula.telefonoAlternativo': 'Teléfono alternativo',
  'matricula.correoElectronicoPrincipal': 'Correo electrónico principal',
  'matricula.correoElectronicoAlternativo': 'Correo electrónico alternativo',
  'matricula.fichaInfomativa': 'Ficha informativa',
  'matricula.matricula': 'Matricular estudiante',
  /* traslados */
  'traslados.solicitarTitle':
		'SOLICITAR UN TRASLADO HACIA MI CENTRO EDUCATIVO',
  'traslados.solicitarDescription':
		'El estudiante se encuentra en otro centro educativo y requiero trasladarlo a mi centro educativo',
  'traslados.trasladarTitle':
		'SOLICITAR UN TRASLADO DESDE MI CENTRO EDUCATIVO',
  'traslados.trasladarDescription':
		'El estudiante se encuentra en mi centro educativo y requiero trasladarlo a otro centro educativo',
  'traslados.solicitudesTitle': 'SOLICITUDES DE TRASLADOS',
  'traslados.solicitudesrDescription':
		'Permite revisar el estado de los traslados solicitados o recibidos',
  'traslados.internosTitle': 'TRASLADOS INTERNOS',
  'traslados.internosDescription':
		'Permite realizar los movimientos de traslado entre ofertas, especialidades o niveles del mismo centro educativo',
  'traslado.confirmMessage':
		'Le comunicamos que la solicitud de traslado se ha concretado, se envió un correo al centro educativo, recordando que debe ACEPTAR o RECHAZAR la solicitud que se ha realizado. Hasta que no se acepte la solicitud de traslado el estudiante no estará matriculado en el centro educativo. Puede contactar al otro director para agilizar el trámite.',
  'traslado.confirmTitle': 'Solicitud de traslado finalizada',
  /* Modelo Ofertas */
  'label.modelo.ofertas': 'Modelo de Ofertas',
  /* Anio Educativo */
  'label.anio.educativo': 'Año educativo',
  /* Curso lectivo */
  'label.cursolectivo': 'Cursos Lectivos',
  'label.calendar': 'Calendarios',
  /* Ofertas */
  'label.ofertas.educativas': 'Ofertas educativas',

  'label.printMembers': 'Imprimir miembros de grupo',
  'label.toDisable': 'Deshabilitar',
  /* Mallas */
  'mallas.mallas': 'Mallas curriculares',
  'menu.mallas': 'Mallas curriculares'
}

// export default es;
