import axios from 'axios'
import { envVariables } from '../../constants/enviroment'

export const GetEstudiantesByEncargado = async (idEncargado)=>{
  try {
      const endpoint = `/api/ExpedienteEstudiante/Miembro/GetEstudiantesByEncargado/${idEncargado}`
      const response = await axios.get(
              `${envVariables.BACKEND_URL}${endpoint}`
      )
      return response.data
    } catch (e) {
      return { error: e.message }
  }
}
export const GetEstudiantesEncargadoById = async (idEsutiante)=>{
  try {
      const endpoint = `/api/ExpedienteEstudiante/Miembro/GetEstudianteEncargadoByid/${idEsutiante}`
      const response = await axios.get(
              `${envVariables.BACKEND_URL}${endpoint}`
      )
      return response.data
    } catch (e) {
      return { error: e.message }
  }
}
export const getStudentDataFilter = async (filterText, filterType, fallecidos = null, soloEstudiantes = null) => {
  try {
    const response = await axios.get(
    `${envVariables.BACKEND_URL}/api/Identidad/GetByFilter/${filterText}/${filterType}${fallecidos !== null ? `?IncluirFallecido=${fallecidos}` : ''}${
      soloEstudiantes !== null
        ? `${
            fallecidos !== null ? '&' : '?'
          }SoloEstudiantes=${soloEstudiantes}`
        : ''
    }`
    )

    return response
  }
  catch(e) {
    return e
  }
}