export const GROUPS_LOAD = 'GROUPS_LOAD'
export const GROUPS_LOADING = 'GROUPS_LOADING'
export const GROUPS_LOAD_BY_INSTITUTION = 'GROUPS_LOAD_BY_INSTITUTION'
export const GROUPS_LOAD_CENTER_OFFERS = 'GROUPS_LOAD_CENTER_OFFERS'
export const GROUPS_LOAD_MEMBERS = 'GROUPS_LOAD_MEMBERS'
export const GROUPS_LOAD_ALL_MEMBERS = 'GROUPS_LOAD_ALL_MEMBERS'
export const GROUPS_LOAD_MEMBERS_WITHOUT_GROUP =
	'GROUPS_LOAD_MEMBERS_WITHOUT_GROUP'
export const GROUPS_LOAD_MEMBERS_BY_LEVEL = 'GROUPS_LOAD_MEMBERS_BY_LEVEL'
export const GROUPS_REFRESH = 'GROUPS_REFRESH'
export const GROUPS_CONDITIONS_LOAD = 'GROUPS_CONDITIONS_LOAD'
export const GROUPS_ACTIVE_CALENDAR_LOAD = 'GROUPS_ACTIVE_CALENDAR_LOAD'
export const GROUPS_CURRENT_CONDITIONS = 'GROUPS_CURRENT_CONDITIONS'
export const GROUPS_BY_OFFER_LOAD = 'GROUPS_BY_OFFER_LOAD'
export const GROUPS_LOAD_INCIDENTS = 'GROUPS_LOAD_INCIDENTS'
export const GROUPS_LOAD_INCIDENTS_TYPES = 'GROUPS_LOAD_INCIDENTS_TYPES'
export const GROUPS_LOAD_MEMBERS_BY_SUBJECT_GROUP =
	'GROUPS_LOAD_MEMBERS_BY_SUBJECT_GROUP'
export const GROUPS_LOAD_BLOQUES = 'GROUPS_LOAD_BLOQUES'
export const GROUPS_LOAD_MEMBERS_BY_OFFER = 'GROUPS_LOAD_MEMBERS_BY_OFFER'
export const GROUPS_LOAD_MEMBERS_BY_GROUP = 'GROUPS_LOAD_MEMBERS_BY_GROUP'
export const GROUPS_LOAD_FILTERED_STUDENTS = 'GROUPS_LOAD_FILTERED_STUDENTS'
export const GET_CENTER_OFFERS = 'GET_CENTER_OFFERS'
export const GROUPS_LOAD_CENTER_OFFERS_SPECIALTY_BY_INSTITUTION =
	'GROUPS_LOAD_CENTER_OFFERS_SPECIALTY_BY_INSTITUTION'
export const GROUPS_ERROR = 'GROUPS_ERROR'
export const GET_CENTER_OFFERS_FOR_TRASLADOS = 'GET_CENTER_OFFERS_FOR_TRASLADOS'
export const GET_GRUPOS_BY_NIVEL_OFERTA_ID = 'GET_GRUPOS_BY_NIVEL_OFERTA_ID'
export interface PaginatedGroupMemberRequest {
	Pagina: number
	CantidadPagina: number
	TipoColumna?: string
	Busqueda?: string
	InstitucionId: number
	GrupoId: number
}
export interface PaginatedGroupMemberWithoutGroupRequest {
	Pagina: number
	CantidadPagina: number
	TipoColumna?: string
	Busqueda?: string
	InstitucionId: number
	NivelId: number
	GrupoId?: number
}
