export const INSTITUCION_ADD = 'INSTITUCION_ADD'
export const INSTITUCION_LOAD = 'INSTITUCION_LOAD'
export const INSTITUCION_CLEAN = 'INSTITUCION_CLEAN'
export const INSTITUCION_LOADING = 'INSTITUCION_LOADING'
export const INSTITUCIONES_LOAD = 'INSTITUCIONES_LOAD'
export const INSTITUCIONES_LOADING = 'INSTITUCIONES_LOADING'
export const INSTITUCION_EDIT = 'INSTITUCION_EDIT'
export const INSTITUCIONES_DELETE = 'INSTITUCIONES_DELETE'
export const INSTITUCION_ERROR = 'INSTITUCION_ERROR'
export const INSTITUTION_LOAD_SEDES = 'INSTITUTION_LOAD_SEDES'
export const CLEAR_INSTITUTIONS = 'CLEAR_INSTITUTIONS'
export const LOAD_INSTITUTIONS = 'LOAD_INSTITUTIONS'
export const SELECT_SHARED_RESOURCE = 'SELECT_SHARED_RESOURCE'
export const LOAD_SHARED_RESOURCES = 'LOAD_SHARED_RESOURCES'
export const DELETE_SHARED_RESOURCES = 'DELETE_SHARED_RESOURCES'
export const LOAD_DATOS_DIRECTOR = 'LOAD_DATOS_DIRECTOR'
export const CLEAR_DATOS_DIRECTOR = 'CLEAR_DATOS_DIRECTOR'
export const LOAD_REGIONAL_LOCATION = 'LOAD_REGIONAL_LOCATION'
export const INSTITUCION_LOAD_INFORMACION_PRESUPUESTARIA = 'INSTITUCION_LOAD_INFORMACION_PRESUPUESTARIA'
export const INSTITUCION_LOAD_OFFERS = 'INSTITUCION_LOAD_OFFERS'
export const LOAD_CURRENT_MEMBER_DATA = 'LOAD_CURRENT_MEMBER_DATA'
export const LOAD_IDENTIDAD_MEMBER_DATA = 'LOAD_IDENTIDAD_MEMBER_DATA'
export const LOAD_CURRENT_AUX_ORGANIZATION = 'LOAD_CURRENT_AUX_ORGANIZATION'
export const LOADT_AUX_ORGANIZATION_MEMBERS = 'LOADT_AUX_ORGANIZATION_MEMBERS'
export const LOAD_CREATED_MEMBER = 'LOAD_CREATED_MEMBER'
export const LOAD_UPDATED_MEMBER = 'LOAD_UPDATED_MEMBER'
export const LOAD_INSTITUTION_STATES = 'LOAD_INSTITUTION_STATES'
export const LOAD_CENTROS_BY_REGIONAL = 'LOAD_CENTROS_BY_REGIONAL'
export const LOAD_CENTROS_BY_CIRCUITO = 'LOAD_CENTROS_BY_CIRCUITO'
