export const IDENTIDAD_LOAD_STUDENT = 'IDENTIDAD_LOAD_STUDENT'
export const IDENTIDAD_STUDENT_ERROR = 'IDENTIDAD_STUDENT_ERROR'
export const IDENTIDAD_STUDENT_LOADING = 'IDENTIDAD_STUDENT_LOADING'
export const IDENTIDAD_CLEAR_STUDENT = 'IDENTIDAD_CLEAR_STUDENT'
export const IDENTIDAD_UPDATE_IDENTIDAD = 'IDENTIDAD_UPDATE_IDENTIDAD'
export const IDENTIDAD_UPDATE_RESIDENCIA = 'IDENTIDAD_UPDATE_RESIDENCIA'
export const IDENTIDAD_SET_WIZARD_ID = 'IDENTIDAD_SET_WIZARD_ID'
export const IDENTIDAD_SET_WIZARD_ID_DATOS = 'IDENTIDAD_SET_WIZARD_ID_DATOS'
export const IDENTIDAD_SET_WIZARD_STEPS = 'IDENTIDAD_SET_WIZARD_STEPS'
export const IDENTIDAD_SAVE_WIZARD = 'IDENTIDAD_SAVE_WIZARD'
export const IDENTIDAD_BITACORAS_LOADING = 'IDENTIDAD_BITACORAS_LOADING'
export const IDENTIDAD_BITACORAS_LOAD = 'IDENTIDAD_BITACORAS_LOAD'
export const IDENTIDAD_BITACORAS_ERROR = 'IDENTIDAD_BITACORAS_ERROR'

export const IDENTIDAD_WIZARD_SET_DATA = 'IDENTIDAD_WIZARD_SET_DATA'
export const IDENTIDAD_WIZARD_SET_NAV = 'IDENTIDAD_WIZARD_SET_NAV'
export const IDENTIDAD_WIZARD_CLEAR_DATA = 'IDENTIDAD_WIZARD_CLEAR_DATA'
export const IDENTIDAD_WIZARD_CLEAR_NAV = 'IDENTIDAD_WIZARD_CLEAR_NAV'
