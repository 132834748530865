import React from 'react'
import { ReactComponent as Logo } from '../../../assets/images/SintiksV2Buho.svg'
import { useTranslation } from 'react-i18next'
const NoInstitutionSelected = () => {
    const { t } = useTranslation()
    return (
        <div style={{ display:'grid', width:'100%', height:'100%',alignContent: 'center',justifyContent: 'center' }}>
            <div align="center"><Logo width='75px' height="75px" style={{transform:'scale(2)'}}/></div>
            <b>
                <h1>{t("estudiantes>traslados>gestion_traslados>seleccionar", "Debe seleccionar un centro educativo en el buscador de centros educativos.")}</h1>
            </b>
        </div>
    )
}

export default NoInstitutionSelected