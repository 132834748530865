export const IDENTIFICATION_LOAD = 'IDENTIFICATION_LOAD'
export const IDENTIFICATION_ADD_CATALOGS = 'IDENTIFICATION_ADD_CATALOGS'
export const IDENTIFICATION_CLEAN = 'IDENTIFICATION_CLEAN'
export const IDENTIFICATION_LOADING = 'IDENTIFICATION_LOADING'
export const IDENTIFICATION_ERROR = 'IDENTIFICATION_ERROR'
export const IDENTIFICATION_LOAD_FICHA = 'IDENTIFICATION_LOAD_FICHA'
export const IDENTIFICATION_LOAD_FICHA_DATOS_EDUCATIVOS = 'IDENTIFICATION_LOAD_FICHA_DATOS_EDUCATIVOS'
export const IDENTIFICATION_LOAD_FICHA_MEMBERS = 'IDENTIFICATION_LOAD_FICHA_MEMBERS'
export const CLEAN_FICHA = 'CLEAN_FICHA'
export const LOAD_MATRICULA_HISTORY = 'LOAD_MATRICULA_HISTORY'
