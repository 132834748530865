import React, { useEffect } from 'react'
import styled from 'styled-components'
interface ITabProps {
    children: React.ReactNode
    tabId: string
    title: string
    active?: boolean
}
export const HorizontalTab: React.FC<ITabProps> = (props) => {
    return <div>
        {props.children}
    </div>
}

interface IPropsTabContainer {
    children: React.ReactNode
}
export const HorizontalTabContainer: React.FC<IPropsTabContainer> = (props) => {
    const [selected, setSelected] = React.useState(null)
    
    useEffect(() => {
    
        const map = React.Children.map(props.children,child => child) 
        if(map.length > 0)
            setSelected(map[0])
    }, [])
    
    return (
        <Container>
            <Header>
                {
                    React.Children.map(props.children, (child:React.ReactElement) => {
                        return <StyledLabel selected={selected?.props?.tabId == child.props.tabId} onClick={() => setSelected(child)}>{child.props.title}</StyledLabel>
                    })
                }
            </Header>
            {selected &&
                React.Children.map(props.children, (child:React.ReactElement) => {
                    return <section style={{ display: selected.props.tabId == child.props.tabId ? 'block' : 'none', paddingLeft: '10px' }}>
                        {child}
                    </section>

                })
            }
        </Container>
    )
}
const StyledLabel = styled.label`
    cursor: pointer;
    border-bottom: 2px solid lightgrey;
    padding: 10px 0px;
    &:last-child {
        border-bottom: none;
        padding-bottom: 10px;
    }
    border-radius: 10px 0 0 10px;
    transition: background-color 0.2s;
    &:hover {
        background-color: lightgrey;
    }
    ${props=>props.selected ? 'background-color: lightgrey;' : ''}
`
const Container = styled.article`
    display: grid;
    grid-template-columns: 20% 80%;
    padding: 15px 5px;
`
const Header = styled.header`
    display: flex;
    flex-direction: column;
    border-right: solid 2px lightgrey;
    height: 100%;
    /*gap: 10px;*/
`

