import axios from 'axios'
import { envVariables } from '../../constants/enviroment'

export const obtenerHoraServidor = async () =>{
    try {
      const response = await axios.get<any>(`${envVariables.BACKEND_URL}/api/Admin/Users/ObtenerHoraServer`)
      const { data } = response
      
      return data
    } catch (e) {
      throw e
    }
  }