export const EARLY_ALERT_LOADING = 'EARLY_ALERT_LOADING'
export const EARLY_ALERT_LOAD = 'EARLY_ALERT_LOAD'
export const EARLY_ALERT_ERROR = 'EARLY_ALERT_ERROR'
export const EARLY_ALERT_FILTER = 'EARLY_ALERT_FILTER'
export const EARLY_ALERT_CLEAR = 'EARLY_ALERT_CLEAR'
export const CHANGE_FILTER_OPTION = 'CHANGE_FILTER_OPTION'
export const CHANGE_COLUMN = 'CHANGE_COLUMN'

export const CONTEXTS_LOADING = 'CONTEXTS_LOADING'
export const CONTEXTS_INTERNO_LOAD = 'CONTEXTS_INTERNO_LOAD'
export const CONTEXTS_EXTERNO_LOAD = 'CONTEXTS_EXTERNO_LOAD'
export const CONTEXTS_ERROR = 'CONTEXTS_ERROR'

export const ALERTS_LOADING = 'ALERTS_LOADING'
export const ALERT_LOAD = 'ALERT_LOAD'
export const ALERTS_ERRROS = 'ALERTS_ERRROS'

export const STATISTICS_LOADING = 'STATISTICS_LOADING'
export const STATISTICS_ALERTS_LOAD = 'STATISTICS_ALERTS_LOAD'
export const STATISTICS_ALERTS_ENROLLMENT_LOAD =
	'STATISTICS_ALERTS_ENROLLMENT_LOAD'
export const STATISTICS_ALERTS_PERCENT = 'STATISTICS_ALERTS_PERCENT'
export const STATISTICS_ALERTS_INCIDENTS = 'STATISTICS_ALERTS_INCIDENTS'
export const STATISTICS_ERROR = 'STATISTICS_ERROR'

export const ALERTS_CATALOG_LOADING = 'ALERTS_CATALOG_LOADING'
export const ALERTS_CATALOG_LOAD = 'ALERTS_CATALOG_LOAD'
export const ALERTS_CATALOG_FAIL = 'ALERTS_CATALOG_FAIL'

export const ALERTS_STATISTICS_LOADING = 'ALERTS_STATISTICS_LOADING'
export const ALERTS_STATISTICS_LOAD = 'ALERTS_STATISTICS_LOAD'
export const ALERTS_STATISTICS_FAIL = 'ALERTS_STATISTICS_FAIL'

export const ALERTS_RESPONSIBLE_LOADING = 'ALERTS_RESPONSIBLE_LOADING'
export const ALERTS_RESPONSIBLE_LOAD = 'ALERTS_RESPONSIBLE_LOAD'
export const ALERTS_RESPONSIBLE_FAIL = 'ALERTS_RESPONSIBLE_FAIL'

export const ALERTS_CONTEXT_LOADING = 'ALERTS_CONTEXT_LOADING'
export const ALERTS_CONTEXT_LOAD = 'ALERTS_CONTEXT_LOAD'
export const ALERTS_CONTEXT_FAIL = 'ALERTS_CONTEXT_FAIL'

export const ALERTS_DIMENSION_LOADING = 'ALERTS_DIMENSION_LOADING'
export const ALERTS_DIMENSION_LOAD = 'ALERTS_DIMENSION_LOAD'
export const ALERTS_DIMENSION_FAIL = 'ALERTS_DIMENSION_FAIL'

export const ALERTS_STUDENTS_LOADING = 'ALERTS_STUDENTS_LOADING'
export const ALERTS_STUDENTS_LOAD = 'ALERTS_STUDENTS_LOAD'
export const ALERTS_STUDENT_DETAIL_LOAD = 'ALERTS_STUDENT_DETAIL_LOAD'
export const ALERTS_STUDENTS_FAIL = 'ALERTS_STUDENTS_FAIL'

export const ALERTS_ACTIVES_LOADING = 'ALERTS_ACTIVES_LOADING'
export const ALERTS_ACTIVES_LOAD = 'ALERTS_ACTIVES_LOAD'
export const ALERTS_ACTIVES_FAIL = 'ALERTS_ACTIVES_FAIL'

export const ALERTS_STUDENT_STEPS_LOADING = 'ALERTS_STUDENT_STEPS_LOADING'
export const ALERTS_STUDENT_STEPS_LOAD = 'ALERTS_STUDENT_STEPS_LOAD'
export const ALERTS_STUDENT_STEP_LOAD = 'ALERTS_STUDENT_STEP_LOAD'
export const ALERTS_STUDENT_STEPS_FAIL = 'ALERTS_STUDENT_STEPS_FAIL'

export const ALERTS_REQUEST_LOADING = 'ALERTS_REQUEST_LOADING'
export const ALERTS_REQUEST_SENT_LOAD = 'ALERTS_REQUEST_SENT_LOAD'
export const ALERTS_REQUEST_RECEIVED_LOAD = 'ALERTS_REQUEST_RECEIVED_LOAD'
export const ALERTS_REQUEST_APPROVED_LOAD = 'ALERTS_REQUEST_APPROVED_LOAD'
export const ALERTS_REQUEST_REJECTED_LOAD = 'ALERTS_REQUEST_REJECTED_LOAD'
export const ALERTS_REQUEST_FAIL = 'ALERTS_REQUEST_FAIL'

export const ALERTS_GLOBAL_STEPS_LOADING = 'ALERTS_GLOBAL_STEPS_LOADING'
export const ALERTS_GLOBAL_STEPS_LOAD = 'ALERTS_GLOBAL_STEPS_LOAD'
export const ALERTS_GLOBAL_STEPS_FAIL = 'ALERTS_GLOBAL_STEPS_FAIL'

export const GET_ESTUDIANTE_CON_ALERTA = 'GET_ESTUDIANTE_CON_ALERTA'
export const GET_ALERTAS_POR_ESTUDIANTE = 'GET_ALERTAS_POR_ESTUDIANTE'
export const ALERTS_CLEAR = 'ALERTS_CLEAR'
