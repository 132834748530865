import React from 'react'
import styled from 'styled-components'

interface ITabProps {
    children: React.ReactNode
    tabId?: number
    title: string
    active?: boolean
}
export const Tab: React.FC<ITabProps> = (props) => {
    return <div>
        {props.children}
    </div>
}
interface ITabContainerProps {
    children: React.ReactNode
}
export const TabContainer: React.FC<ITabContainerProps> = (props) => {
    const [active, setActive] = React.useState<any>(null)
    React.useEffect(() => {
        const map = React.Children.map(props.children,child => child) 
        if(map?.length > 0)
            setActive(map[0])
    }, [])
    // const childrens = React.useMemo(() => {
    //     return React.Children.map(props.children, (child, index) => {
    //         if (React.isValidElement(child)) {
    //             //return React.cloneElement(child, { sayHello });
    //             return { title: child.props.title, children: child.props.children };
    //         }
    //     })
    // }, [])
    // const childrenWithProps = (child) => {
    //     // Checking isValidElement is the safe way and avoids a
    //     // typescript error too.
    //     if (React.isValidElement(child)) {
    //         return React.cloneElement(child, { active:1 });
    //     }
    //     return child;
    // }
    return (
        <article>
            <StyledHeader>
                {React.Children.map(props.children,(child, index) => {
                    if (React.isValidElement(child)) {
                        return <StyledLabel active={active?.props?.tabId == child.props.tabId} onClick={() => setActive(child)}>{child.props.title}</StyledLabel>
                    }
                })}
            </StyledHeader>
            {active &&
                React.Children.map(props.children, (child:React.ReactElement) => {
                    return <section style={{ display: active.props.tabId == child.props.tabId ? 'block' : 'none', boxSizing: 'border-box', width:'100%'}}>
                        {child}
                    </section>

                })
            }
        </article>
    )
}
const StyledHeader = styled.header`
    display: flex;
    gap: 20px;
    color: #475569;
    font-weight: bold;
`
const StyledLabel = styled.label`
    ${props => {
        if (props.active == true)
            return `color: #02597B;
            padding-bottom: 5px;
            border-bottom: 2px solid #02597B;`
    }}
    cursor: pointer;
    transition: color 0.2s;
    &:hover {
        color: #02597B;
    }
`
export default TabContainer