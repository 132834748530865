import axios from 'axios'
import { envVariables } from '../../constants/enviroment'
import moment from 'moment'

export const fetchInformacionGeneral = (identidadId) => {
    const url = `${envVariables.BACKEND_URL}/api/ExpedienteEstudiante/Expediente/InformacionGeneral`

    return axios.get(url, {
        params: { identidadId }
    }).then((r) => {
        const { data } = r
        return data
    })
}

export const postInformacionGeneral = (obj) => {
    const url = `${envVariables.BACKEND_URL}/api/ExpedienteEstudiante/Expediente/InformacionGeneral`

    return axios.post(url, obj).then((r) => {
        const { data } = r
        return data
    })
}

export const fetchDatosAdicionales = (identidadId) => {
    const url = `${envVariables.BACKEND_URL}/api/ExpedienteEstudiante/Expediente/DatosAdicionales`

    return axios.get(url, {
        params: { identidadId }
    }).then((r) => {
        const { data } = r
        return data
    })
}

export const postDatosAdicionales = (obj) => {
    const url = `${envVariables.BACKEND_URL}/api/ExpedienteEstudiante/Expediente/DatosAdicionales`

    return axios.post(url, obj).then((r) => {
        const { data } = r
        return data
    })
}

export const fetchDatosContacto = (identidadId) => {
    const url = `${envVariables.BACKEND_URL}/api/ExpedienteEstudiante/Expediente/DatosContacto`

    return axios.get(url, {
        params: { identidadId }
    }).then((r) => {
        const { data } = r
        return data
    })
}

export const postDatosContacto = (obj) => {
    const url = `${envVariables.BACKEND_URL}/api/ExpedienteEstudiante/Expediente/DatosContacto`

    return axios.post(url, obj).then((r) => {
        const { data } = r
        return data
    })
}


export const fetchDatosApoyo = (identidadId) => {
    const url = `${envVariables.BACKEND_URL}/api/ExpedienteEstudiante/Expediente/DatosApoyo`

    return axios.get(url, {
        params: { identidadId }
    }).then((r) => {
        const { data } = r
        return data
    })
}

export const postDatosApoyo = (formData) => {
    const url = `${envVariables.BACKEND_URL}/api/ExpedienteEstudiante/Expediente/DatosApoyo`

    return axios.post(url, formData).then((r) => {
        const { data } = r
        return data
    })
}

export const getDatosBasicosEstudiante = (idEstudiante) => {
    return axios.get(
        `${envVariables.BACKEND_URL}/api/Matricula/GetDatosEducativos/${idEstudiante}`
    ).then(r => {
        return r.data
    })
}
export const getCalificacionesByEstudiante = (idEstudiante: number, idInstitucion) => {
    const endpoint = `${envVariables.BACKEND_URL}/api/Areas/Externos/Estudiante/GetAllCalificacionesByEstudianteId/${idEstudiante}/${idInstitucion}/1/50`
    return axios.get(endpoint).then(r => {
        return r.data
    })
}

export const createMultipleAssistanceApi = (assis: any) => {
    return axios.post(
        `${envVariables.BACKEND_URL}/api/AsistenciaEstudianteGrupoAsignatura/CreateOrUpdateMultiple`,
        assis
    )
}

export const getAssitanceByAsignaturaGrupoFechaLeccionApi = (asignaturaGrupoId, fecha: Date, leccionId) => {
    try {

        const url = new URL(`${envVariables.BACKEND_URL}/api/AsistenciaEstudianteGrupoAsignatura/GetAllAsistenciaByAsignaturaGrupo`)
        const newAssistances = {}
        url.searchParams.append('asignaturaGrupoId', asignaturaGrupoId)
        //url.searchParams.append('leccionId',leccionId)
        url.searchParams.append('fecha', moment(fecha).format('YYYY-MM-DD'))

        const response: any = axios.get(url.toString())

        return response.then(r => r.data)
            .then(r => {
                r.forEach(assis => {
                    newAssistances[assis?.identidades_Id] = assis
                })
            })

    } catch (e) {
        return { error: e.message }
    }
}

export const getAsistenciasPorcentualbyAsignaturaGrupo = (asignaturaGrupoId) =>{
    const endpoint = `${envVariables.BACKEND_URL}/api/AsistenciaEstudianteGrupoAsignatura/AsistenciaByAsignaturaGrupo`
    const url = new URL(endpoint)
    url.searchParams.append("asignaturaGrupoId",asignaturaGrupoId)
    return axios.get(endpoint).then(r => {
        return r.data
    })
}