export const PERIODO_LOAD = 'PERIODO_LOAD'
export const PERIODO_GET = 'PERIODO_GET'
export const PERIODO_SAVE = 'PERIODO_SAVE'
export const PERIODO_EDIT = 'PERIODO_EDIT'
export const PERIODO_ERROR = 'PERIODO_ERROR'
export const PERIODO_LOADING = 'PERIODO_LOADING'
export const PERIODO_DISABLED = 'PERIODO_DISABLED'
export const PERIODO_CLEAR_ACTIVE = 'PERIODO_CLEAR_ACTIVE'
export const PERIODO_LOAD_BY_CALENDAR = 'PERIODO_LOAD_BY_CALENDAR'
export const PERIODO_CHANGE_STATE = 'PERIODO_CHANGE_STATE'
export const PERIODO_GET_ALL = 'PERIODO_GET_ALL'
export const PERIODO_CALENDARIO_DELETE = 'PERIODO_CALENDARIO_DELETE'
export const PERIODO_CLEAR = 'PERIODO_CLEAR'
