import React from 'react'
import styled from 'styled-components'
const BackButton = (props) => {
  return (
    <StyledButton {...props}>Regresar</StyledButton>
  )
}

const StyledButton = styled.button`
    position: absolute;
    top: 10px;
    left: 350px
`

export default BackButton