export const APOYOS_LOADING = 'APOYOS_LOADING'
export const LOAD_TIPOS = 'LOAD_TIPOS'
export const LOAD_DEPENDENCIAS = 'LOAD_DEPENDENCIAS'
export const LOAD_CATEGORIAS = 'LOAD_CATEGORIAS'
export const LOAD_APOYOS = 'LOAD_APOYOS'
export const APOYOS_LOADING_ITEMS = 'APOYOS_LOADING_ITEMS'
export const LOAD_DISCAPACIDADES = 'LOAD_DISCAPACIDADES'
export const LOAD_RECURSOS = 'LOAD_RECURSOS'
export const LOAD_CONDICIONES = 'LOAD_CONDICIONES'
export const CLEAR_CURRENT_DISCAPACIDADES = 'CLEAR_CURRENT_DISCAPACIDADES'
