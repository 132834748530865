import React from 'react'

interface BackButtonProps extends React.ComponentProps<'button'> {
    text?:string
}
const BackButtonV2:React.FC<BackButtonProps> = (props) => {
  return (
    <button style={{
        cursor: 'pointer',
        position:'absolute',
        borderRadius: '10px',
        border: '1px solid #DCE0CD',
        background: '#02597B',
        padding: '10px 16px',
        color: 'white',
        fontWeight: 'bold',
        top: '5px',
        zIndex: 1001
      }} {...props}>{props.text ? props.text : 'Regresar'}</button>
  )
}

export default BackButtonV2