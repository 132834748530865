enum types {
  GET_ALL_ASSISTS = 'GET_ALL_ASSISTS',
  GET_ASSISTS_PAGINATED = 'GET_ASSISTS_PAGINATED',
  ADD_ASSISTANCE = 'ADD_ASSISTANCE',
  UPDATE_ASSISTANCE = 'UPDATE_ASSISTANCE',
  DELETE_ASSISTANCE = 'DELETE_ASSISTANCE',
  GET_SINGLE_ASSISTANCE = 'GET_SINGLE_ASSISTANCE',
  LOADING_ASSISTANCES = 'LOADING_ASSISTANCES',
  GET_ASSISTANCE_TYPES = 'GET_ASSISTANCE_TYPES',
  GET_ASSISTANCES_BY_IDENTIDAD_ID = 'GET_ASSISTANCES_BY_IDENTIDAD_ID',
  GET_ASSISTANCES_BY_SINGLE_STUDENT_ID = 'GET_ASSISTANCES_BY_SINGLE_STUDENT_ID',
  GET_ASSISTANCE_CONSOLIDADOS = 'GET_ASSISTANCE_CONSOLIDADOS',
  GET_ASSISTANCES_QUANTITY = 'GET_ASSISTANCES_QUANTITY',
  GET_ACUMULADO = 'GET_ACUMULADO',
  GET_ACUMULADOS_MATRICULA = 'GET_ACUMULADOS_MATRICULA',
  UPDATE_RESOURCE = 'UPDATE_RESOURCE',
  GET_ASISTENCIA_ESTUDIANTE_BITACORA = 'GET_ASISTENCIA_ESTUDIANTE_BITACORA',
  GET_ASISTENCIA_BY_TIPO = 'GET_ASISTENCIA_BY_TIPO'
}

export default types
